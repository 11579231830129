import React, { useCallback, useEffect, useMemo } from "react";
import API from "../../../../services/api";
import GameAPI from "../../../../services/gameApi";
import EventsTable, { EventsWithStatus } from "./EventsTable";
import useAPIRequest from "../../../../hooks/useAPIRequest";
import Container from "../../../atoms/page/Page";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../../atoms/banner/Banner";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import dateFormat from "dateformat";

function OpenEvents() {
  const getGameApiStatus = useCallback(() => {
    return GameAPI.getStatus();
  }, []);

  const callback = useCallback(() => {
    return API.getOpenEvents();
  }, []);

  const [{ inProgress, data, completed, error }, doAPIRequest] = useAPIRequest<
    API.EventResponseSummary[]
  >(callback, { data: [] });

  const { data: gameApiStatus } = useMemoRequest(getGameApiStatus);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const handleRefreshEvent = useCallback(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const events: EventsWithStatus[] | null = useMemo(() => {
    if (!gameApiStatus || !data) {
      return null;
    }

    return data.map((event) => {
      const status = gameApiStatus[event.id] ?? {
        status: "notsetup",
        totalRounds: 0,
        currentRound: 0,
      };
      const gameStarted = status.currentRound > 0;
      if (
        status.status === "closed" &&
        status.currentRound === status.totalRounds
      ) {
        return {
          ...event,
          status: status.status,
          statusText: "Finished",
          statusIcon: "status",
          statusColour: "danger",
        };
      }

      if (!gameStarted) {
        if (event.schedule?.roundType === "scheduled" && status.gameStartTime) {
          return {
            ...event,
            status: status.status,
            statusText: `This event will commence at: ${dateFormat(
              status.gameStartTime,
              "hh:MMtt",
            )} on ${dateFormat(status.gameStartTime, "dd mmmm yyyy")}`,
            statusIcon: "timer",
            statusColour: "secondaryBlue1",
          };
        }
        return {
          ...event,
          status: status.status,
          statusText: "Not commenced",
          statusIcon: "roundStop",
          statusColour: "purple",
        };
      } else if (status.uiStatus === "locked") {
        if (
          event.schedule?.roundType === "scheduled" &&
          status.nextRoundStartTime
        ) {
          return {
            ...event,
            status: status.status,
            statusText: `Resuming at: ${dateFormat(
              status.nextRoundStartTime,
              "hh:MMtt",
            )} on ${dateFormat(status.nextRoundStartTime, "dd mmmm yyyy")}`,
            statusIcon: "timer",
            statusColour: "orange",
          };
        }
        return {
          ...event,
          status: status.status,
          statusText: `Rnd ${status.currentRound} locked`,
          statusIcon: "lock",
          statusColour: "orange",
        };
      }
      if (status.status === "notsetup") {
        return {
          ...event,
          status: status.status,
          statusText: "Not setup yet",
          statusIcon: "rocket",
          statusColour: "grey1",
        };
      }
      return {
        ...event,
        status: status.status,
        statusText: `Rnd ${status.currentRound} in progress`,
        statusIcon: "rocket",
        statusColour: "darkGreen",
      };
    });
  }, [data, gameApiStatus]);

  return (
    <Container>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {completed && events && (
        <EventsTable
          events={events}
          refreshEvents={handleRefreshEvent}
          showStatus
        />
      )}
    </Container>
  );
}

export default OpenEvents;
