import React, { useCallback, useState } from "react";
import { useParams } from "react-router";
import { useMemoRequest } from "../../../hooks/useMemoRequest";
import modelApi from "../../../services/modelApi";
import Banner from "../../atoms/banner/Banner";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import RoundResultsFontMultiplierModal from "./components/RoundResultsFontMultiplierModal";
import RoundResultsSyncer from "./RoundResultsSyncer";
import RoundResultsPresenterLayout from "./RoundResultsPresenterLayout";
import RoundResultsNotGeneratedError from "./components/RoundResultsNotGeneratedError";
import { usePollingModelConfiguration } from "../../../hooks/useModelConfiguration";
import RoundResultsOpenPresentationModal from "./RoundResultsOpenPresentationModal";

const RoundResultsPresenter: React.FC = () => {
  const { eventId } = useParams<{
    eventId: string;
  }>();
  const [showOpenWindowModal, setShowOpenWindowModal] = useState(true);

  const getRoundNumber = useCallback(async () => {
    try {
      const preso = await modelApi.getReportingPresentationState(eventId, {
        bypass: false,
      });
      return preso.round;
    } catch (error) {
      return 0;
    }
  }, [eventId]);

  const { inProgress, data, error } = useMemoRequest<number>(getRoundNumber);

  return (
    <>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {inProgress && <LoadingSpinner />}
      {data != null && (
        <RoundResultsPresenterWithRound eventId={eventId} roundNumber={data} />
      )}
      {showOpenWindowModal && (
        <RoundResultsOpenPresentationModal
          isOpen={showOpenWindowModal}
          eventId={eventId}
          onClose={() => setShowOpenWindowModal(false)}
        />
      )}
    </>
  );
};

const RoundResultsPresenterWithRound: React.FC<{
  eventId: string;
  roundNumber: number;
}> = ({ eventId, roundNumber }) => {
  const [round, setRound] = useState(roundNumber);
  const [textSizeModalOpen, setTextSizeModalOpen] = useState(false);
  const getReportingResults = useCallback(() => {
    return modelApi.getReportingResults(eventId, round);
  }, [eventId, round]);

  const { inProgress, data, error, refresh } =
    useMemoRequest<ModelAPI.Reporting.ReportingResults>(getReportingResults);

  const refreshData = useCallback(() => {
    refresh();
  }, [refresh]);

  const { data: config } = usePollingModelConfiguration(eventId, 10000);

  const notFoundError = error?.message?.includes("not found");

  return (
    <>
      {!notFoundError && error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {notFoundError && (
        <RoundResultsNotGeneratedError
          roundNumber={round}
          handleRoundUpdate={setRound}
        />
      )}
      {inProgress && <LoadingSpinner />}
      {textSizeModalOpen && (
        <RoundResultsFontMultiplierModal
          isOpen={textSizeModalOpen}
          onClose={() => setTextSizeModalOpen(false)}
        />
      )}
      {data && config && (
        <RoundResultsSyncer
          eventId={eventId}
          roundNumber={round}
          data={data}
          onlyThesePages={[]}
          hidePagination
          allowPresentationMode
          syncState
          readSyncState
          ignoreKeyPress
          noAudio
          handleRoundChange={setRound}
        >
          <RoundResultsPresenterLayout
            data={data}
            onSpeakerNotesSaved={refreshData}
            handleRoundChange={setRound}
            modelConfig={config}
          />
        </RoundResultsSyncer>
      )}
    </>
  );
};

export default RoundResultsPresenter;
