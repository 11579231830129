import React, { useCallback } from "react";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import api from "../../../../services/api";
import Banner from "../../../atoms/banner/Banner";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import BusinessLabels from "../../simulations/steps/labels/BusinessLabels";

function Business() {
  const getBusinesses = useCallback(async () => {
    return await api.getBusinessConfig();
  }, []);

  const { data: businesses, inProgress, error } = useMemoRequest(getBusinesses);

  const handleBusinessUpdate = useCallback(
    async (businessId: string, data: Partial<API.BusinessConfigResponse>) => {
      await api.updateBusinessConfig(businessId, data);
    },
    [],
  );

  return (
    <>
      <h3>Business Settings</h3>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {businesses && (
        <BusinessLabels
          businesses={businesses}
          onUpdateContent={handleBusinessUpdate}
        />
      )}
    </>
  );
}

export default Business;
