import { get, post, patch, doDelete, put, download } from "../lib/request";

const HOST = process.env.REACT_APP_API_URL?.includes("localhost")
  ? process.env.REACT_APP_API_URL.replace(
      "localhost",
      process.env.REACT_APP_LOCAL_IP ?? "localhost",
    )
  : process.env.REACT_APP_API_URL;

const HOST_API = process.env.REACT_APP_GAME_API_URL?.includes("localhost")
  ? process.env.REACT_APP_GAME_API_URL.replace(
      "localhost",
      process.env.REACT_APP_LOCAL_IP ?? "localhost",
    )
  : process.env.REACT_APP_GAME_API_URL;

class API {
  token: string;

  constructor() {
    this.token = "";
  }

  headers = () => ({
    Authorization: `Bearer ${this.token}`,
  });

  getOpenEvents = async () => {
    const response = await get<API.EventResponseSummary[]>({
      url: `${HOST}/api/events?state=open`,
      headers: this.headers(),
    });
    return response.data;
  };

  getClosedEvents = async () => {
    const response = await get<API.EventResponseSummary[]>({
      url: `${HOST}/api/events?state=closed`,
      headers: this.headers(),
    });
    return response.data;
  };

  getImportEventsList = async () => {
    const response = await get<API.EventResponseSummary[]>({
      url: `${HOST}/api/import-events`,
      headers: this.headers(),
    });
    return response.data;
  };

  importEvent = async (eventId: string) => {
    const response = await put<API.EventResponse>({
      url: `${HOST}/api/import-events/${eventId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  getEvent = async (eventId: string) => {
    const response = await get<API.EventResponse>({
      url: `${HOST}/api/events/${eventId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  createEvent = async (event: API.EventRequest) => {
    const response = await post<API.EventResponse>({
      url: `${HOST}/api/event`,
      headers: this.headers(),
      body: {
        ...event,
        participants: [],
      },
    });
    return response.data;
  };

  duplicateEvent = async (eventId: string, event: API.EventRequest) => {
    const response = await post<API.EventResponse>({
      url: `${HOST}/api/events/${eventId}/duplicate`,
      headers: this.headers(),
      body: event,
    });
    return response.data;
  };

  editEvent = async (eventId: string, event: API.EventRequest) => {
    const response = await put<API.EventResponse>({
      url: `${HOST}/api/events/${eventId}`,
      headers: this.headers(),
      body: event,
    });
    return response.data;
  };

  closeEvent = async (eventId: string) => {
    const response = await post<API.EventResponseSummary>({
      url: `${HOST}/api/events/${eventId}/close`,
      headers: this.headers(),
    });
    return response.data;
  };

  openEvent = async (eventId: string) => {
    const response = await post<API.EventResponseSummary>({
      url: `${HOST}/api/events/${eventId}/open`,
      headers: this.headers(),
    });
    return response.data;
  };

  deleteEvent = async (eventId: string) => {
    return doDelete<Record<string, never>>({
      url: `${HOST}/api/events/${eventId}`,
      headers: this.headers(),
    });
  };

  closeAndDeleteEvent = async (eventId: string) => {
    await this.closeEvent(eventId);
    return this.deleteEvent(eventId);
  };

  getParticipant = async (eventId: string, participantId: string) => {
    const response = await get<API.ParticipantResponse>({
      url: `${HOST}/api/events/${eventId}/participants/${participantId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  addParticipant = async (
    eventId: string,
    participant: API.ParticipantRequest,
    transferCeo?: boolean,
    sendWelcomeEmail?: boolean,
  ) => {
    const url = new URL(`${HOST}/api/events/${eventId}/participant`);

    if (transferCeo) {
      url.searchParams.set("transferCeo", "true");
    }
    if (sendWelcomeEmail) {
      url.searchParams.set("sendWelcomeEmail", "true");
    }
    const response = await post<API.ParticipantResponse>({
      url: url.href,
      headers: this.headers(),
      body: participant,
    });
    return response.data;
  };

  updateParticipant = async (
    eventId: string,
    participantId: string,
    participant: API.ParticipantRequest,
    transferCeo?: boolean,
    sendWelcomeEmail?: boolean,
  ) => {
    const url = new URL(
      `${HOST}/api/events/${eventId}/participants/${participantId}`,
    );

    if (transferCeo) {
      url.searchParams.set("transferCeo", "true");
    }
    if (sendWelcomeEmail) {
      url.searchParams.set("sendWelcomeEmail", "true");
    }
    const response = await put<API.ParticipantResponse>({
      url: url.href,
      headers: this.headers(),
      body: participant,
    });
    return response.data;
  };

  deleteParticipants = async (
    eventId: string,
    participants: Array<string>,
    transferCeo?: boolean,
  ) => {
    const url = new URL(
      `${HOST}/api/events/${eventId}/participants/bulkDelete`,
    );

    if (transferCeo) {
      url.searchParams.set("transferCeo", "true");
    }
    return post<Record<string, never>>({
      url: url.href,
      headers: this.headers(),
      body: participants,
    });
  };

  deleteAllParticipants = async (eventId: string) => {
    return doDelete<Record<string, never>>({
      url: `${HOST}/api/events/${eventId}/participants`,
      headers: this.headers(),
    });
  };

  uploadParticipants = async (eventId: string, data: File) => {
    const formData = new FormData();
    formData.append("file", data);
    const response = await post<{
      participants: API.ParticipantResponse[];
      failed: number;
      failureReasons: string[];
    }>({
      url: `${HOST}/api/events/${eventId}/participants/upload`,
      headers: { ...this.headers(), "Content-Type": "multipart/form-data" },
      body: formData,
    });
    return response.data;
  };

  exportParticipants = async (event: API.EventResponse) => {
    return download(
      `${HOST}/api/events/${event.id}/export`,
      { headers: this.headers(), responseType: "arraybuffer" },
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      `${event.client} - ${event.name} ${event.streamName ? `- ${event.streamName} ` : ""}- ${event.date} - ${event.id}.xlsx`,
    );
  };

  exportParticipantsList = async (event: API.EventResponse) => {
    return download(
      `${HOST}/api/events/${event.id}/export-participants`,
      { headers: this.headers(), responseType: "arraybuffer" },
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      `${event.client} - ${event.name} ${event.streamName ? `- ${event.streamName} ` : ""}- ${event.date} - ${event.id}-participants.xlsx`,
    );
  };

  exportAttendanceList = async (event: API.EventResponse) => {
    return download(
      `${HOST}/api/events/${event.id}/export-attendance`,
      { headers: this.headers(), responseType: "arraybuffer" },
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      `${event.client} - ${event.name} ${event.streamName ? `- ${event.streamName} ` : ""}- ${event.date} - ${event.id}-attendance.xlsx`,
    );
  };

  downloadParticipantsTemplate = async (eventId: string) => {
    return download(
      `${HOST}/api/events/${eventId}/participants-template`,
      { headers: this.headers(), responseType: "arraybuffer" },
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "Simulation Participants Template.xlsx",
    );
  };

  login = async (email: string, password: string) => {
    const response = await post<API.LoginResponse>({
      url: `${HOST}/api/admin/login`,
      body: {
        email: email,
        password: password,
      },
    });
    this.setToken(response.data.token);
    return response.data;
  };

  verify = async (userId: string, data: API.VerifyRequest) => {
    const response = await post<API.UserResponse>({
      url: `${HOST}/api/users/${userId}/verify`,
      body: data,
    });
    return response.data;
  };

  getGameStatus = async (eventId: string) => {
    const response = await get<API.GameStatus>({
      url: `${HOST_API}/api/games/${eventId}/status`,
      headers: this.headers(),
    });
    return response.data;
  };

  startRound = async (eventId: string, round: number) => {
    const response = await put({
      url: `${HOST_API}/api/admin/games/${eventId}/rounds/${round}/start`,
      headers: this.headers(),
    });
    return response.data;
  };

  closeRound = async (eventId: string, round: number) => {
    const response = await put({
      url: `${HOST_API}/api/admin/games/${eventId}/rounds/${round}/close`,
      headers: this.headers(),
    });
    return response.data;
  };

  unlockAllTeams = async (eventId: string, round: number) => {
    const response = await put({
      url: `${HOST_API}/api/admin/games/${eventId}/rounds/${round}/unlock`,
      headers: this.headers(),
    });
    return response.data;
  };

  lockAllTeams = async (eventId: string, round: number) => {
    const response = await put({
      url: `${HOST_API}/api/admin/games/${eventId}/rounds/${round}/lock`,
      headers: this.headers(),
    });
    return response.data;
  };

  updateTimer = async (eventId: string, round: number, delta: number) => {
    const response = await put({
      url: `${HOST_API}/api/admin/games/${eventId}/rounds/${round}/teams/timer`,
      headers: this.headers(),
      body: {
        delta: Math.round(delta),
      },
    });
    return response.data;
  };

  updateBonusTimers = async (eventId: string, round: number, delta: number) => {
    const response = await put({
      url: `${HOST_API}/api/admin/games/${eventId}/rounds/${round}/teams/timer/bonus`,
      headers: this.headers(),
      body: {
        delta: Math.round(delta),
      },
    });
    return response.data;
  };

  updateTeamBonusTimer = async (
    eventId: string,
    round: number,
    team: number,
    delta: number,
  ) => {
    const response = await put({
      url: `${HOST_API}/api/admin/games/${eventId}/rounds/${round}/teams/${team}/timer/bonus`,
      headers: this.headers(),
      body: {
        delta: Math.round(delta),
      },
    });
    return response.data;
  };

  clearBonusTimers = async (eventId: string, round: number) => {
    const response = await put({
      url: `${HOST_API}/api/admin/games/${eventId}/rounds/${round}/teams/timer/bonus/clear`,
      headers: this.headers(),
    });
    return response.data;
  };

  clearTeamBonusTimer = async (
    eventId: string,
    round: number,
    team: number,
  ) => {
    const response = await put({
      url: `${HOST_API}/api/admin/games/${eventId}/rounds/${round}/teams/${team}/timer/bonus/clear`,
      headers: this.headers(),
    });
    return response.data;
  };

  updateTeamInfo = async (
    eventId: string,
    team: number,
    teamInfo: Partial<{ name: string; strategy: string }>,
  ) => {
    const response = await put({
      url: `${HOST_API}/api/admin/games/${eventId}/teams/${team}`,
      headers: this.headers(),
      body: {
        ...teamInfo,
      },
    });
    return response.data;
  };

  resetGame = async (eventId: string) => {
    await put({
      url: `${HOST_API}/api/admin/games/${eventId}/setup/reset`,
      headers: this.headers(),
    });
  };

  setupGame = async (
    eventId: string,
    { uploadInitialResults }: { uploadInitialResults: boolean },
  ) => {
    await put({
      url: `${HOST_API}/api/admin/games/${eventId}/setup`,
      headers: this.headers(),
      body: {
        gameId: eventId,
        uploadInitialResults,
      },
    });
  };

  setToken(token: string) {
    this.token = token;
  }

  getClients = async () => {
    const response = await get<API.ClientResponse[]>({
      url: `${HOST}/api/clients`,
      headers: this.headers(),
    });
    return response.data;
  };

  getClient = async (clientId: string) => {
    const response = await get<API.ClientResponse>({
      url: `${HOST}/api/clients/${clientId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  deleteClient = async (clientId: string) => {
    return doDelete<Record<string, never>>({
      url: `${HOST}/api/clients/${clientId}`,
      headers: this.headers(),
    });
  };

  editClient = async (clientId: string, client: API.ClientRequest) => {
    const response = await put<API.ClientResponse>({
      url: `${HOST}/api/clients/${clientId}`,
      headers: this.headers(),
      body: client,
    });
    return response.data;
  };

  createClient = async (client: API.ClientRequest) => {
    const response = await post<API.ClientResponse>({
      url: `${HOST}/api/client`,
      headers: this.headers(),
      body: client,
    });
    return response.data;
  };

  exportClientList = async (fileName: string) => {
    return download(
      `${HOST}/api/clients/export`,
      { headers: this.headers(), responseType: "arraybuffer" },
      "text/csv",
      `${fileName}.csv`,
    );
  };

  getUsers = async ({
    type,
    clients,
    status,
  }: {
    type?: string[];
    clients?: string[];
    status?: "active";
  }) => {
    const queries = new URLSearchParams({});
    if (type?.length) queries.append("type", type.join(","));
    if (clients?.length) queries.append("clients", clients.join(","));
    if (status) queries.append("status", "active");
    const response = await get<API.UserResponse[]>({
      url: `${HOST}/api/users/?${queries.toString()}`,
      headers: this.headers(),
    });
    return response.data;
  };

  getUser = async (userId: string) => {
    const response = await get<API.UserResponse>({
      url: `${HOST}/api/users/${userId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  deleteUser = async (userId: string) => {
    return doDelete<Record<string, never>>({
      url: `${HOST}/api/users/${userId}`,
      headers: this.headers(),
    });
  };

  editUser = async (userId: string, user: API.UserRequest) => {
    const response = await put<API.UserResponse>({
      url: `${HOST}/api/users/${userId}`,
      headers: this.headers(),
      body: user,
    });
    return response.data;
  };

  createUser = async (user: API.UserRequest) => {
    const response = await post<API.UserResponse>({
      url: `${HOST}/api/user`,
      headers: this.headers(),
      body: user,
    });
    return response.data;
  };

  exportUserList = async (fileName: string) => {
    return download(
      `${HOST}/api/users/export`,
      { headers: this.headers(), responseType: "arraybuffer" },
      "text/csv",
      `${fileName}.csv`,
    );
  };

  getAuditLogs = async (userId?: string) => {
    const response = await get<API.AuditResponse[]>({
      url: `${HOST}/api/audit${userId ? `?userId=${userId}` : ""}`,
      headers: this.headers(),
    });
    return response.data;
  };

  getEventAuditLog = async (eventId: string) => {
    const response = await get<API.AuditResponse[]>({
      url: `${HOST}/api/audit?type=event&referenceId=${eventId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  getBrandings = async () => {
    const response = await get<API.BrandingResponse[]>({
      url: `${HOST}/api/brandings`,
      headers: this.headers(),
    });
    return response.data;
  };

  getBranding = async (brandingId: string) => {
    const response = await get<API.BrandingResponse>({
      url: `${HOST}/api/brandings/${brandingId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  deleteBranding = async (brandingId: string) => {
    return doDelete<Record<string, never>>({
      url: `${HOST}/api/brandings/${brandingId}`,
      headers: this.headers(),
    });
  };

  editBranding = async (brandingId: string, client: API.BrandingRequest) => {
    const response = await put<API.BrandingResponse>({
      url: `${HOST}/api/brandings/${brandingId}`,
      headers: this.headers(),
      body: client,
    });
    return response.data;
  };

  createBranding = async (user: API.BrandingRequest) => {
    const response = await post<API.BrandingResponse>({
      url: `${HOST}/api/branding`,
      headers: this.headers(),
      body: user,
    });
    return response.data;
  };

  uploadBrandingImage = async (data: File) => {
    const formData = new FormData();
    formData.append("file", data);
    const response = await put<API.ClientBrandingImageResponse>({
      url: `${HOST}/api/branding-image/upload`,
      headers: this.headers(),
      body: formData,
    });
    return response.data;
  };

  uploadClientLogo = async (clientId: string, data: File) => {
    const formData = new FormData();
    formData.append("file", data);
    const response = await put<API.ClientResponse>({
      url: `${HOST}/api/clients/${clientId}/logo/upload`,
      headers: this.headers(),
      body: formData,
    });
    return response.data;
  };

  sendResetPasswordEmail = async (payload: API.ResetPasswordEmailRequest) => {
    return post<Record<string, never>>({
      url: `${HOST}/api/reset-password-email`,
      headers: this.headers(),
      body: payload,
    });
  };

  resetPassword = async (
    userId: string,
    resetPassword: API.ResetPasswordRequest,
  ) => {
    return post<Record<string, never>>({
      url: `${HOST}/api/users/${userId}/reset-password`,
      headers: this.headers(),
      body: resetPassword,
    });
  };

  resendVerificationEmail = async (userId: string) => {
    return post<Record<string, never>>({
      url: `${HOST}/api/users/${userId}/verify/resend`,
      headers: this.headers(),
      body: {},
    });
  };

  getSimulations = async (clientIds: string[] = [], state?: string) => {
    const url = new URL(`${HOST}/api/simulations`);

    if (clientIds && clientIds.length > 0) {
      url.searchParams.set("clients", clientIds.join(","));
    }

    if (state) {
      url.searchParams.set("state", state);
    }

    const response = await get<API.SimulationResponse[]>({
      url: url.href,
      headers: this.headers(),
    });
    return response.data;
  };

  getSimulation = async (simulationId: string) => {
    const response = await get<API.SimulationResponse>({
      url: `${HOST}/api/simulations/${simulationId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  getSpecialProjects = async () => {
    const response = await get<API.SpecialProjectsResponse>({
      url: `${HOST}/api/config/projects`,
      headers: this.headers(),
    });
    return response.data;
  };

  deleteSimulation = async (simulationId: string) => {
    return doDelete<Record<string, never>>({
      url: `${HOST}/api/simulations/${simulationId}`,
      headers: this.headers(),
    });
  };

  editSimulation = async (
    simulationId: string,
    payload: API.SimulationUpdateRequest,
  ) => {
    const response = await put<API.SimulationResponse>({
      url: `${HOST}/api/simulations/${simulationId}`,
      headers: this.headers(),
      body: payload,
    });
    return response.data;
  };

  createSimulation = async (payload: API.SimulationCreateRequest) => {
    const response = await post<API.SimulationResponse>({
      url: `${HOST}/api/simulation`,
      headers: this.headers(),
      body: payload,
    });
    return response.data;
  };

  cloneSimulation = async (simulationId: string) => {
    const response = await post<API.SimulationResponse>({
      url: `${HOST}/api/simulations/${simulationId}/duplicate`,
      headers: this.headers(),
      body: {},
    });
    return response.data;
  };

  getAssessments = async (clientIds?: string[]) => {
    const response = await get<API.AssessmentResponse[]>({
      url:
        clientIds && clientIds.length
          ? `${HOST}/api/assessments?clients=${clientIds.join(",")}`
          : `${HOST}/api/assessments`,
      headers: this.headers(),
    });
    return response.data;
  };

  getAssessment = async (assessmentId: string) => {
    const response = await get<API.AssessmentResponse>({
      url: `${HOST}/api/assessments/${assessmentId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  deleteAssessment = async (assessmentId: string) => {
    return doDelete<Record<string, never>>({
      url: `${HOST}/api/assessments/${assessmentId}`,
      headers: this.headers(),
    });
  };

  editAssessment = async (
    assessmentId: string,
    client: API.AssessmentRequest,
  ) => {
    const response = await put<API.AssessmentResponse>({
      url: `${HOST}/api/assessments/${assessmentId}`,
      headers: this.headers(),
      body: client,
    });
    return response.data;
  };

  createAssessment = async (assessment: API.AssessmentRequest) => {
    const response = await post<API.AssessmentResponse>({
      url: `${HOST}/api/assessment`,
      headers: this.headers(),
      body: assessment,
    });
    return response.data;
  };

  cloneAssessment = async (assessmentId: string) => {
    const response = await post<API.AssessmentResponse>({
      url: `${HOST}/api/assessments/${assessmentId}/duplicate`,
      headers: this.headers(),
      body: {},
    });
    return response.data;
  };

  getAssessmentGroup = async (assessmentId: string, groupId: string) => {
    const response = await get<API.AssessmentGroupResponse>({
      url: `${HOST}/api/assessments/${assessmentId}/groups/${groupId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  getAssessmentGroups = async (assessmentId: string) => {
    const response = await get<API.AssessmentGroupResponse[]>({
      url: `${HOST}/api/assessments/${assessmentId}/groups`,
      headers: this.headers(),
    });
    return response.data;
  };

  createAssessmentGroup = async (
    assessmentId: string,
    assessmentGroup: API.AssessmentGroupRequest,
  ) => {
    const response = await post<API.AssessmentGroupResponse>({
      url: `${HOST}/api/assessments/${assessmentId}/groups`,
      headers: this.headers(),
      body: assessmentGroup,
    });
    return response.data;
  };

  editAssessmentGroup = async (
    assessmentId: string,
    assessmentGroupId: string,
    assessmentGroup: API.AssessmentGroupRequest,
  ) => {
    const response = await put<API.AssessmentGroupResponse>({
      url: `${HOST}/api/assessments/${assessmentId}/groups/${assessmentGroupId}`,
      headers: this.headers(),
      body: assessmentGroup,
    });
    return response.data;
  };

  deleteAssessmentGroup = async (
    assessmentId: string,
    assessmentGroup: API.AssessmentGroupResponse,
  ) => {
    const response = await doDelete<Record<string, never>>({
      url: `${HOST}/api/assessments/${assessmentId}/groups/${assessmentGroup.id}`,
      headers: this.headers(),
    });
    return response.data;
  };

  createAssessmentQuesiton = async (
    assessmentId: string,
    groupId: string,
    assessmentQuestion: API.AssessmentQuestionRequest,
  ) => {
    const response = await post<API.AssessmentQuestionResponse>({
      url: `${HOST}/api/assessments/${assessmentId}/groups/${groupId}/questions`,
      headers: this.headers(),
      body: assessmentQuestion,
    });
    return response.data;
  };

  getAssessmentQuesiton = async (
    assessmentId: string,
    groupId: string,
    questionId: string,
  ) => {
    const response = await get<API.AssessmentQuestionResponse>({
      url: `${HOST}/api/assessments/${assessmentId}/groups/${groupId}/questions/${questionId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  updateAssessmentQuestion = async (
    assessmentId: string,
    groupId: string,
    questionId: string,
    assessmentQuestion: API.AssessmentQuestionRequest,
  ) => {
    const response = await put<API.AssessmentQuestionResponse>({
      url: `${HOST}/api/assessments/${assessmentId}/groups/${groupId}/questions/${questionId}`,
      headers: this.headers(),
      body: assessmentQuestion,
    });
    return response.data;
  };

  deleteAssessmentQuestion = async (
    assessmentId: string,
    groupId: string,
    questionId: string,
  ) => {
    const response = await doDelete<Record<string, never>>({
      url: `${HOST}/api/assessments/${assessmentId}/groups/${groupId}/questions/${questionId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  updateScheduledRound = async (
    gameId: string,
    round: number,
    data: Partial<API.UpdateScheduledRoundRequest>,
  ) => {
    const response = await patch<Record<string, never>>({
      url: `${HOST_API}/api/admin/games/${gameId}/setup/rounds/${round}`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  removeDevice = async (gameId: string, deviceId: string) => {
    const response = await doDelete<Record<string, never>>({
      url: `${HOST_API}/api/admin/games/${gameId}/device/${deviceId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  getBusinessConfig = async () => {
    const response = await get<API.BusinessConfigResponse[]>({
      url: `${HOST}/api/config/businesses`,
      headers: this.headers(),
    });
    return response.data;
  };

  updateBusinessConfig = async (
    businessId: string,
    data: Partial<API.BusinessConfigResponse>,
  ) => {
    const response = await patch<API.BusinessConfigResponse>({
      url: `${HOST}/api/config/businesses/${businessId}`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  getHelpConfig = async () => {
    const response = await get<API.HelpConfigResponse[]>({
      url: `${HOST}/api/config/help`,
      headers: this.headers(),
    });
    return response.data;
  };

  updateHelpConfig = async (
    helpId: string,
    data: Partial<API.HelpConfigResponse>,
  ) => {
    const response = await patch<API.HelpConfigResponse>({
      url: `${HOST}/api/config/help/${helpId}`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  getLabelsConfig = async () => {
    const response = await get<API.LabelConfigResponse[]>({
      url: `${HOST}/api/config/labels`,
      headers: this.headers(),
    });
    return response.data;
  };

  updateLabelConfig = async (
    labelId: string,
    data: Partial<API.LabelConfigResponse>,
  ) => {
    const response = await patch<API.LabelConfigResponse>({
      url: `${HOST}/api/config/labels/${labelId}`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  getSummaryMetricConfig = async () => {
    const response = await get<API.SummaryMetricConfig[]>({
      url: `${HOST}/api/config/summary-metrics`,
      headers: this.headers(),
    });
    return response.data;
  };

  updateSummaryMetricConfig = async (
    metricId: string,
    data: Partial<API.SummaryMetricConfig>,
  ) => {
    const response = await patch<API.UpdateSummaryMetricConfigResponse>({
      url: `${HOST}/api/config/summary-metrics/${metricId}`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  getFinancialSummaryMetricConfig = async () => {
    const response = await get<API.FinancialSummaryMetricConfig[]>({
      url: `${HOST}/api/config/financial-summary-metrics`,
      headers: this.headers(),
    });
    return response.data;
  };

  updateFinancialSummaryMetricConfig = async (
    metricId: string,
    data: Partial<API.FinancialSummaryMetricConfig>,
  ) => {
    const response =
      await patch<API.UpdateFinancialSummaryMetricConfigResponse>({
        url: `${HOST}/api/config/financial-summary-metrics/${metricId}`,
        headers: this.headers(),
        body: data,
      });
    return response.data;
  };

  getDealConfig = async () => {
    const response = await get<API.DealConfigResponse[]>({
      url: `${HOST}/api/config/deals`,
      headers: this.headers(),
    });
    return response.data;
  };

  updateDealConfig = async (
    dealId: string,
    data: Partial<API.DealConfigResponse>,
  ) => {
    const response = await patch<API.UpdateDealConfigResponse>({
      url: `${HOST}/api/config/deals/${dealId}`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  getExcoConfig = async () => {
    const response = await get<API.ExcoConfigResponse[]>({
      url: `${HOST}/api/config/exco`,
      headers: this.headers(),
    });
    return response.data;
  };

  uploadAssessmentImage = async (assessmentId: string, data: File) => {
    const formData = new FormData();
    formData.append("file", data);
    const response = await post<API.AssessmentImageResponse>({
      url: `${HOST}/api/assessments/${assessmentId}/images/upload`,
      headers: { ...this.headers(), "Content-Type": "multipart/form-data" },
      body: formData,
    });
    return response.data;
  };

  getAssessmentImageUrl = (assessmentId: string, imageId: string) => {
    return `${HOST}/api/assessments/${assessmentId}/images/${imageId}`;
  };

  updateExcoConfig = async (
    excoId: string,
    data: Partial<API.ExcoConfigResponse>,
  ) => {
    const response = await patch<API.UpdateExcoConfigResponse>({
      url: `${HOST}/api/config/exco/${excoId}`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  updateExcoOptionConfig = async (
    excoId: string,
    optionId: string,
    data: Partial<API.ExcoOptionConfig>,
  ) => {
    const response = await patch<API.UpdateExcoConfigResponse>({
      url: `${HOST}/api/config/exco/${excoId}/option/${optionId}`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  updateProjectConfig = async (
    projectId: string,
    data: Partial<API.SpecialProject>,
  ) => {
    const response = await patch<API.UpdateSpecialProjectsResponse>({
      url: `${HOST}/api/config/projects/${projectId}`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  updateProjectImpactConfig = async (
    projectId: string,
    impactId: string,
    data: Partial<API.SpecialProjectImpact>,
  ) => {
    const response = await patch<API.UpdateSpecialProjectsImpactResponse>({
      url: `${HOST}/api/config/projects/${projectId}/impacts/${impactId}`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  getBroadcastMessages = async () => {
    const response = await get<API.BroadcastMessage[]>({
      url: `${HOST}/api/config/broadcast-messages/`,
      headers: this.headers(),
    });
    return response.data;
  };

  createBroadcastMessage = async (data: API.BroadcastMessageRequest) => {
    const response = await post<API.BroadcastMessage>({
      url: `${HOST}/api/config/broadcast-messages/`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  updateBroadcastMessage = async (
    messageId: string,
    data: API.BroadcastMessageRequest,
  ) => {
    const response = await patch<API.BroadcastMessage>({
      url: `${HOST}/api/config/broadcast-messages/${messageId}`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  deleteBroadcastMessage = async (messageId: string) => {
    return doDelete<Record<string, never>>({
      url: `${HOST}/api/config/broadcast-messages/${messageId}`,
      headers: this.headers(),
    });
  };

  getHelpManuals = async () => {
    const response = await get<API.HelpManualResponse[]>({
      url: `${HOST}/api/config/help-manuals/`,
      headers: this.headers(),
    });
    return response.data;
  };

  getHelpManual = async (helpManualsId: string) => {
    const response = await get<API.HelpManualResponse>({
      url: `${HOST}/api/config/help-manuals/${helpManualsId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  updateHelpManual = async (
    helpManualsId: string,
    data: API.HelpManualRequest,
  ) => {
    const response = await put<API.HelpManualResponse>({
      url: `${HOST}/api/config/help-manuals/${helpManualsId}`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  deleteHelpManual = async (helpManualsId: string) => {
    const response = await doDelete<Record<string, never>>({
      url: `${HOST}/api/config/help-manuals/${helpManualsId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  createHelpManual = async (data: API.HelpManualRequest) => {
    const response = await post<API.HelpManualResponse>({
      url: `${HOST}/api/config/help-manual`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  uploadHelpManual = async (helpManualsId: string, data: File) => {
    const formData = new FormData();
    formData.append("file", data);
    const response = await post<API.HelpManualResponse>({
      url: `${HOST}/api/config/help-manuals/${helpManualsId}/upload`,
      headers: this.headers(),
      body: formData,
    });
    return response.data;
  };

  getAssessmentResponses = async (eventId: string) => {
    const response = await get<API.AssessmentAnswerResponse[]>({
      url: `${HOST_API}/api/games/${eventId}/assessment/answers`,
      headers: this.headers(),
    });
    return response.data;
  };

  markAnswer = async (answerId: string, mark: number, comment: string) => {
    const response = await put<Record<string, never>>({
      url: `${HOST_API}/api/answers/${answerId}/mark`,
      headers: this.headers(),
      body: { mark, comment },
    });
    return response.data;
  };

  getReportingTemplates = async (clientIds: string[] = []) => {
    const url = new URL(`${HOST}/api/reporting-templates`);

    if (clientIds && clientIds.length > 0) {
      url.searchParams.set("clients", clientIds.join(","));
    }

    const response = await get<API.ReportingTemplateResponse[]>({
      url: url.href,
      headers: this.headers(),
    });
    return response.data;
  };

  getReportingTemplate = async (reportingTemplateId: string) => {
    const response = await get<API.ReportingTemplateResponse>({
      url: `${HOST}/api/reporting-templates/${reportingTemplateId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  deleteReportingTemplate = async (reportingTemplateId: string) => {
    return doDelete<Record<string, never>>({
      url: `${HOST}/api/reporting-templates/${reportingTemplateId}`,
      headers: this.headers(),
    });
  };

  editReportingTemplate = async (
    reportingTemplateId: string,
    template: API.ReportingTemplateRequest,
  ) => {
    const response = await put<API.ReportingTemplateResponse>({
      url: `${HOST}/api/reporting-templates/${reportingTemplateId}`,
      headers: this.headers(),
      body: template,
    });
    return response.data;
  };

  patchReportingTemplate = async (
    reportingTemplateId: string,
    template: API.ReportingTempaltePatchRequest,
  ) => {
    const response = await patch<API.ReportingTemplateResponse>({
      url: `${HOST}/api/reporting-templates/${reportingTemplateId}`,
      headers: this.headers(),
      body: template,
    });
    return response.data;
  };

  createReportingTemplate = async (template: API.ReportingTemplateRequest) => {
    const response = await post<API.ReportingTemplateResponse>({
      url: `${HOST}/api/reporting-template`,
      headers: this.headers(),
      body: template,
    });
    return response.data;
  };

  cloneReportingTemplate = async (reportingTemplateId: string) => {
    const response = await post<API.ReportingTemplateResponse>({
      url: `${HOST}/api/reporting-templates/${reportingTemplateId}/duplicate`,
      headers: this.headers(),
      body: {},
    });
    return response.data;
  };

  updateReportingTemplateLayout = async (
    reportingTemplateId: string,
    layoutId: string,
    data: API.ReportingTemplateLayoutUpdateRequest,
  ) => {
    const response = await patch<API.ReportingTemplateLayoutResponse>({
      url: `${HOST}/api/reporting-templates/${reportingTemplateId}/layouts/${layoutId}`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  deleteReportingTemplateLayout = async (
    reportingTemplateId: string,
    layoutId: string,
  ) => {
    return doDelete<Record<string, never>>({
      url: `${HOST}/api/reporting-templates/${reportingTemplateId}/layouts/${layoutId}`,
      headers: this.headers(),
    });
  };

  createReportingTemplateLayout = async (
    reportingTemplateId: string,
    data: API.ReportingTemplateLayoutCreateRequest,
  ) => {
    const response = await post<API.ReportingTemplateLayoutResponse>({
      url: `${HOST}/api/reporting-templates/${reportingTemplateId}/layouts`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  uploadReportingImage = async (data: File) => {
    const formData = new FormData();
    formData.append("file", data);
    const response = await post<API.ReportingImageResponse>({
      url: `${HOST}/api/reporting-templates/images/upload`,
      headers: { ...this.headers(), "Content-Type": "multipart/form-data" },
      body: formData,
    });
    return response.data;
  };

  uploadReportingVideo = async (data: File) => {
    const formData = new FormData();
    formData.append("file", data);
    const response = await post<API.ReportingVideoResponse>({
      url: `${HOST}/api/reporting-templates/videos/upload`,
      headers: { ...this.headers(), "Content-Type": "multipart/form-data" },
      body: formData,
    });
    return response.data;
  };

  getReportingVideoUrl = async (filename: string, md5: string) => {
    const response = await post<{
      url: string;
      video: API.ReportingVideoResponse;
    }>({
      url: `${HOST}/api/reporting-templates/videos/upload-url`,
      headers: this.headers(),
      body: { filename, md5 },
    });
    return response.data;
  };

  uploadReportingVideoToS3 = async (data: File, url: string) => {
    const response = await put<any>({
      url,
      headers: { "Content-Type": data.type },
      body: data,
    });
    return response;
  };

  sendWelcomeEmail = async (eventId: string, participantIds?: string[]) => {
    const body = participantIds ? { participants: participantIds } : {};
    const response = await post<Record<string, never>>({
      url: `${HOST}/api/events/${eventId}/participants/send-welcome-email`,
      headers: this.headers(),
      body,
    });
    return response.data;
  };

  // List all economic scenarios
  listEconomicScenarios = async () => {
    const response = await get<API.EconomicScenarioResponse[]>({
      url: `${HOST}/api/economic-scenarios`,
      headers: this.headers(),
    });
    return response.data;
  };

  // Get a single economic scenario
  getEconomicScenario = async (scenarioId: string) => {
    const response = await get<API.EconomicScenarioResponse>({
      url: `${HOST}/api/economic-scenarios/${scenarioId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  // Create a new economic scenario
  createEconomicScenario = async (scenario: API.EconomicScenarioRequest) => {
    const response = await post<API.EconomicScenarioResponse>({
      url: `${HOST}/api/economic-scenarios`,
      headers: this.headers(),
      body: scenario,
    });
    return response.data;
  };

  // Update an economic scenario
  updateEconomicScenario = async (
    scenarioId: string,
    updateData: API.EconomicScenarioUpdateRequest,
  ) => {
    const response = await patch<API.EconomicScenarioResponse>({
      url: `${HOST}/api/economic-scenarios/${scenarioId}`,
      headers: this.headers(),
      body: updateData,
    });
    return response.data;
  };

  // Delete an economic scenario
  deleteEconomicScenario = async (scenarioId: string) => {
    return doDelete<Record<string, never>>({
      url: `${HOST}/api/economic-scenarios/${scenarioId}`,
      headers: this.headers(),
    });
  };

  // Duplicate an economic scenario
  duplicateEconomicScenario = async (
    scenarioId: string,
    scenarioData: API.EconomicScenarioRequest,
  ) => {
    const response = await post<API.EconomicScenarioResponse>({
      url: `${HOST}/api/economic-scenarios/${scenarioId}/duplicate`,
      headers: this.headers(),
      body: scenarioData,
    });
    return response.data;
  };

  getTablets = async () => {
    const response = await get<API.TabletMappingResponse>({
      url: `${HOST}/api/tablets`,
      headers: this.headers(),
    });
    return response.data;
  };

  getTabletById = async (tabletId: string) => {
    const response = await get<API.TabletDetailResponse>({
      url: `${HOST}/api/tablets/${tabletId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  updateTabletMappings = async (updatedMappings: API.TabletUpdateRequest) => {
    const response = await put<API.TabletMappingResponse>({
      url: `${HOST}/api/tablets`,
      headers: this.headers(),
      body: updatedMappings,
    });
    return response.data;
  };

  deleteTablet = async (tabletId: number) => {
    return doDelete<Record<string, never>>({
      url: `${HOST}/api/tablets/${tabletId}`,
      headers: this.headers(),
    });
  };

  deleteTabletMapping = async () => {
    return doDelete<Record<string, never>>({
      url: `${HOST}/api/tablets`,
      headers: this.headers(),
    });
  };

  addTablet = async (tabletCreateRequest: API.TabletCreateRequest) => {
    const response = await post<API.TabletDetailResponse>({
      url: `${HOST}/api/tablets`,
      body: tabletCreateRequest,
      headers: this.headers(),
    });
    return response.data;
  };

  sendTabletConfigEmail = async (eventId: string) => {
    const response = await post<Record<string, never>>({
      url: `${HOST}/api/tablets/send-email`,
      headers: this.headers(),
      body: { eventId },
    });
    return response.data;
  };

  getGlobalModelVariables = async () => {
    const response = await get<API.GlobalModelVariablesResponse[]>({
      url: `${HOST}/api/global-model-variables`,
      headers: this.headers(),
    });
    return response.data;
  };

  updateGlobalModelVariables = async (
    data: API.GlobalModelVariablesResponse[],
  ) => {
    const response = await patch<API.GlobalModelVariablesResponse[]>({
      url: `${HOST}/api/global-model-variables`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  getReportingLayoutConfig = async () => {
    const response = await get<API.BaseReportingTemplateLayoutResponse[]>({
      url: `${HOST}/api/config/reporting-layouts`,
      headers: this.headers(),
    });
    return response.data;
  };

  updateReportingLayoutConfig = async (
    layoutId: string,
    data: API.ReportingTemplateLayoutUpdateRequest,
  ) => {
    const response = await patch<API.BaseReportingTemplateLayoutResponse[]>({
      url: `${HOST}/api/config/reporting-layouts/${layoutId}`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  patchBaseReportingLayouts = async (
    data: API.BaseReportingTemplateLayoutResponse[],
  ) => {
    const response = await patch<API.BaseReportingTemplateLayoutResponse[]>({
      url: `${HOST}/api/config/reporting-layouts`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };
  guessParticipantName = async (eventId: string, email: string) => {
    const response = await post<{ firstName: string; lastName: string }[]>({
      url: `${HOST}/api/ai/guess-participant-name`,
      headers: this.headers(),
      body: { eventId, email },
    });
    return response.data;
  };
}

export default new API();
