import React, { useMemo } from "react";
import classNames from "classnames";
import { useOpenEvents } from "../../../hooks/useOpenEvents";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import Text from "../../atoms/text/Text";
import "./OpenEventSidebar.scss";
import Clickable from "../../atoms/clickable/Clickable";
import { shortDate } from "../../../lib/date";
import Avatar from "../../atoms/avatar/Avatar";

interface Props {
  eventId?: string;
  onChange: (gameId: string) => void;
}

export const formatGameInfo = (event: API.EventResponseSummary) => {
  let endBit = "";
  if (event.streamName) {
    endBit = ` - ${event.streamName}`;
  }
  return `${shortDate(event.date)}${endBit}`;
};

function OpenEventSidebar({ eventId, onChange }: Props) {
  const { inProgress, data } = useOpenEvents();
  const events = useMemo(() => {
    return data ? data.filter((e) => e.isGameSetup) : [];
  }, [data]);

  return (
    <div className="openeventsidebar">
      <VerticalGroup
        className={classNames("events-container", {
          "full-height": inProgress || !data,
        })}
        wide
      >
        <InlineGroup className="pl-2 pr-4 pt-4 pb-2">
          <Text colour="green" size="lg" center>
            Games
          </Text>
        </InlineGroup>
        {(inProgress || !data) && (
          <div className="spinner-container">
            <LoadingSpinner />
          </div>
        )}
        {!inProgress && !!data && (
          <VerticalGroup spaceBetweenElements={1} wide>
            {events.map((e) => (
              <Clickable
                onClick={() => onChange(e.id)}
                className={classNames("openeventssidebarevent", {
                  active: e.id === eventId,
                })}
                key={e.id}
              >
                <VerticalGroup spread wide spaceBetweenElements={2}>
                  <InlineGroup verticalCenter center>
                    <Text size="sm" medium>
                      {e.name}
                    </Text>
                  </InlineGroup>
                  <InlineGroup verticalCenter spread block>
                    <Text size="xs" medium>
                      {formatGameInfo(e)}
                    </Text>
                    <Avatar
                      size={5}
                      id={e.id}
                      src={e.branding?.brandingImage?.imageUrl}
                      alt={e.client}
                      defaultText={e.client[0]}
                    />
                  </InlineGroup>
                </VerticalGroup>
              </Clickable>
            ))}
          </VerticalGroup>
        )}
      </VerticalGroup>
    </div>
  );
}

export default OpenEventSidebar;
