import React from "react";
import { FontSizeMultiplier } from "../results-text/ResultsText";

export interface ResultsState {
  eventId: string;
  roundNumber: number;
  hideTeamPositions: boolean;
  hidePagination: boolean;
  hideHeader: boolean;
  noAnimations: boolean;
  noAudio: boolean;
  noVideo: boolean;
  allowCustomTemplateEdits: boolean;
  renderKey: number;
  refreshPresentationKey: number;
  pageIndex: number;
  clicks: number;
  version: number;
  cast: boolean;
  onCustomTemplateEdit: (val: API.ReportingTemplateLayoutUpdateRequest) => void;
  pages: API.ReportingTemplateLayoutResponse[];
  allowPresentationMode: boolean;
  fontMultiplier: FontSizeMultiplier;
  syncState: boolean;
  readSyncState: boolean;
  ignoreKeyPress: boolean;
  ignoreFontMultiplier: boolean;
  showRemoteCloseButton: boolean;
  onLayoutUpdate?: (
    layoutId: string,
    data: API.ReportingTemplateLayoutUpdateRequest,
  ) => void;
}

/**
 * Action
 */

interface UpdatePages {
  type: "UpdatePages";
  payload: API.ReportingTemplateLayoutResponse[];
}

interface UpdateHideTeamPositions {
  type: "UpdateHideTeamPositions";
  payload: boolean;
}

interface UpdateHidePagination {
  type: "UpdateHidePagination";
  payload: boolean;
}

interface UpdateHideHeader {
  type: "UpdateHideHeader";
  payload: boolean;
}

interface UpdateNoAnimations {
  type: "UpdateNoAnimations";
  payload: boolean;
}
interface UpdateNoAudio {
  type: "UpdateNoAudio";
  payload: boolean;
}
interface UpdateNoVideo {
  type: "UpdateNoVideo";
  payload: boolean;
}

interface UpdateAllowCustomTemplateEdits {
  type: "UpdateAllowCustomTemplateEdits";
  payload: boolean;
}

interface UpdateOnCustomTemplateEdit {
  type: "UpdateOnCustomTemplateEdit";
  payload: (val: API.ReportingTemplateLayoutUpdateRequest) => void;
}

interface UpdateRenderKey {
  type: "UpdateRenderKey";
  payload: number;
}
interface UpdateRefreshPresentationKey {
  type: "UpdateRefreshPresentationKey";
  payload: number;
}

interface UpdatePageIndex {
  type: "UpdatePageIndex";
  payload: number;
}

interface updateClicks {
  type: "updateClicks";
  payload: number;
}

interface UpdateAllowPresentationMode {
  type: "UpdateAllowPresentationMode";
  payload: boolean;
}

interface UpdateFontMultiplier {
  type: "UpdateFontMultiplier";
  payload: { fontMultiplier: FontSizeMultiplier; version?: number };
}

interface GoNextPage {
  type: "GoNextPage";
}

interface GoPreviousPage {
  type: "GoPreviousPage";
}

interface UpdatePageAndClicks {
  type: "UpdatePageAndClicks";
  payload: { pageIndex: number; clicks: number; version: number };
}
interface UpdatePageClicksAndRoundNumber {
  type: "UpdatePageClicksAndRoundNumber";
  payload: {
    pageIndex: number;
    clicks: number;
    round: number;
    version: number;
  };
}

interface AddClick {
  type: "AddClick";
}

interface UpdateCastState {
  type: "UpdateCastState";
  payload: { cast: boolean; version?: number };
}

interface UpdateRound {
  type: "UpdateRound";
  payload: { round: number; version?: number };
}

export type Actions =
  | UpdatePages
  | UpdateHideTeamPositions
  | UpdateHidePagination
  | UpdateHideHeader
  | UpdateNoAnimations
  | UpdateNoAudio
  | UpdateNoVideo
  | UpdateAllowCustomTemplateEdits
  | UpdateOnCustomTemplateEdit
  | UpdateRenderKey
  | UpdateRefreshPresentationKey
  | UpdatePageIndex
  | updateClicks
  | UpdateAllowPresentationMode
  | UpdateFontMultiplier
  | GoNextPage
  | GoPreviousPage
  | UpdatePageAndClicks
  | UpdatePageClicksAndRoundNumber
  | AddClick
  | UpdateCastState
  | UpdateRound;

/**
 * Reducer
 */

export const reducer: React.Reducer<ResultsState, Actions> = (
  state,
  action,
) => {
  switch (action.type) {
    case "UpdatePages": {
      return {
        ...state,
        pages: action.payload,
      };
    }
    case "UpdateHideTeamPositions":
      return {
        ...state,
        hideTeamPositions: action.payload,
      };
    case "UpdateHidePagination":
      return {
        ...state,
        hidePagination: action.payload,
      };
    case "UpdateHideHeader":
      return {
        ...state,
        hideHeader: action.payload,
      };
    case "UpdateNoAnimations":
      return {
        ...state,
        noAnimations: action.payload,
      };
    case "UpdateNoAudio":
      return {
        ...state,
        noAudio: action.payload,
      };
    case "UpdateNoVideo":
      return {
        ...state,
        noVideo: action.payload,
      };
    case "UpdateAllowCustomTemplateEdits":
      return {
        ...state,
        allowCustomTemplateEdits: action.payload,
      };
    case "UpdateOnCustomTemplateEdit":
      return {
        ...state,
        onCustomTemplateEdit: action.payload,
      };
    case "UpdateRenderKey":
      return {
        ...state,
        renderKey: action.payload,
      };
    case "UpdateRefreshPresentationKey":
      return {
        ...state,
        refreshPresentationKey: action.payload,
      };
    case "UpdatePageIndex":
      return {
        ...state,
        pageIndex: action.payload,
        version: new Date().valueOf(),
      };
    case "updateClicks":
      return {
        ...state,
        clicks: action.payload,
        version: new Date().valueOf(),
      };
    case "UpdateAllowPresentationMode":
      return {
        ...state,
        allowPresentationMode: action.payload,
      };
    case "UpdateFontMultiplier":
      return {
        ...state,
        fontMultiplier: action.payload.fontMultiplier,
        version: action.payload.version ?? new Date().valueOf(),
      };
    case "UpdateCastState":
      return {
        ...state,
        cast: action.payload.cast,
        version: action.payload.version ?? new Date().valueOf(),
      };
    case "UpdateRound":
      return {
        ...state,
        roundNumber: action.payload.round,
        clicks: 0,
        pageIndex: 0,
        version: action.payload.version ?? new Date().valueOf(),
      };
    case "GoNextPage": {
      return {
        ...state,
        pageIndex: state.pageIndex + 1,
        clicks: 0,
        version: new Date().valueOf(),
      };
    }
    case "GoPreviousPage": {
      return {
        ...state,
        pageIndex: Math.max(0, state.pageIndex - 1),
        clicks: 0,
        version: new Date().valueOf(),
      };
    }
    case "UpdatePageAndClicks": {
      return {
        ...state,
        pageIndex: action.payload.pageIndex,
        clicks: action.payload.clicks,
        version: action.payload.version,
      };
    }
    case "UpdatePageClicksAndRoundNumber": {
      return {
        ...state,
        pageIndex: action.payload.pageIndex,
        clicks: action.payload.clicks,
        roundNumber: action.payload.round,
        version: action.payload.version,
      };
    }
    case "AddClick": {
      return {
        ...state,
        clicks: state.clicks + 1,
        version: new Date().valueOf(),
      };
    }
    default:
      return state;
  }
};
