import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import IntegerDropdown from "../../organisms/integer-dropdown/IntegerDropdown";
import ModelAPI from "../../../services/modelApi";
import api from "../../../services/api";
import { useMemoRequest } from "../../../hooks/useMemoRequest";
import useIsMobile from "../../../hooks/useIsMobile";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../atoms/banner/Banner";
import { Actions, ModelState, reducer } from "../model/state";
import { ModelContext, useModelContextValue } from "../model/context";
import getTeamName from "../model/utils/getTeamName";
import SimpleModal from "../../organisms/standard-modal/SimpleModal";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import FacilitatorModelAdjustments from "./FacilitatorModelAdjustments";
import IconButton from "../../molecules/iconbutton/IconButton";
import Text from "../../atoms/text/Text";

interface GetConfigurationAndStatusResponse {
  configuration: ModelAPI.ConfigurationResponse;
  status: API.GameStatus;
}

interface GameControllerFacilitatorAdjustmentsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRecalculate: () => void;
}

const GameControllerFacilitatorAdjustmentsModal: React.FC<
  GameControllerFacilitatorAdjustmentsModalProps
> = ({ isOpen, onClose, onRecalculate }) => {
  const { eventId } = useParams<{ eventId: string }>();
  const isMobile = useIsMobile();
  const [hasAdjustments, setHasAdjustments] = useState(false);
  const [isRecalculating, setIsRecalculating] = useState(false);
  const [state, dispatch] = useReducer<React.Reducer<ModelState, Actions>>(
    reducer,
    {
      config: undefined,
      selectedRound: 0,
      selectedTeam: 1,
      gameStatus: undefined,
    },
  );
  const handleAdjustmentMade = useCallback(() => {
    setHasAdjustments(true);
  }, []);
  const handleModalClose = useCallback(() => {
    if (!hasAdjustments) {
      onClose();
    }
  }, [hasAdjustments, onClose]);

  const getConfigurationAndStatus =
    useCallback(async (): Promise<GetConfigurationAndStatusResponse> => {
      const configuration = await ModelAPI.getModelConfiguration(eventId);
      const status = await api.getGameStatus(eventId);
      return { configuration, status };
    }, [eventId]);

  const { inProgress, data, error } =
    useMemoRequest<GetConfigurationAndStatusResponse>(
      getConfigurationAndStatus,
    );

  useEffect(() => {
    if (!isOpen) {
      setHasAdjustments(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (data) {
      dispatch({ type: "UpdateConfig", payload: data.configuration });
      dispatch({
        type: "UpdateSelectedRound",
        payload: data.configuration.currentRound,
      });
      dispatch({ type: "UpdateGameStatus", payload: data.status });
    }
  }, [data]);

  const { numberOfTeams, totalNumberOfTeams, numberOfRounds } = useMemo(() => {
    if (!state.config)
      return { numberOfTeams: 0, totalNumberOfTeams: 0, numberOfRounds: 0 };
    const { numberOfTeams, numberOfCpuTeams, numberOfRounds } = state.config;
    const totalNumberOfTeams = numberOfTeams + numberOfCpuTeams;
    return { numberOfTeams, totalNumberOfTeams, numberOfRounds };
  }, [state.config]);

  const modelContext = useModelContextValue(state, dispatch);
  const handleCloseOrRecalculate = useCallback(async () => {
    if (hasAdjustments) {
      onClose();
      setIsRecalculating(true);
      onRecalculate();
      setHasAdjustments(false);
      setIsRecalculating(false);
    } else {
      onClose();
    }
  }, [hasAdjustments, onRecalculate, onClose]);

  const modalContent = (
    <ModelContext.Provider value={modelContext}>
      {inProgress && <LoadingSpinner />}
      {error && <Banner type="error" active message={error.message} />}
      {!inProgress && !error && state.config && state.gameStatus && (
        <VerticalGroup full spaceBetweenElements={4}>
          <InlineGroup verticalCenter spread block>
            <Text bold size="xl">
              Adjustments
            </Text>
            <InlineGroup
              className={isMobile ? "pb-4" : ""}
              spaceBetweenElements={2}
              verticalCenter
              right
              block
            >
              {!isMobile && (
                <>
                  <IntegerDropdown
                    small
                    labelFormatter={(team: number) =>
                      getTeamName(team, numberOfTeams, state.gameStatus!.teams)
                    }
                    min={1}
                    max={totalNumberOfTeams}
                    onChange={(selected) =>
                      modelContext.updateSelectedTeam(selected.value)
                    }
                    value={state.selectedTeam}
                  />
                  <IntegerDropdown
                    smallish
                    labelFormatter={(round: number) => `Round ${round}`}
                    min={0}
                    max={numberOfRounds}
                    onChange={(selected) =>
                      modelContext.updateSelectedRound(selected.value)
                    }
                    value={state.selectedRound}
                  />
                </>
              )}
            </InlineGroup>
          </InlineGroup>
          {isMobile && (
            <InlineGroup block spread>
              <IntegerDropdown
                small
                labelFormatter={(team: number) =>
                  getTeamName(team, numberOfTeams, state.gameStatus!.teams)
                }
                min={1}
                max={totalNumberOfTeams}
                onChange={(selected) =>
                  modelContext.updateSelectedTeam(selected.value)
                }
                value={state.selectedTeam}
              />
              <IntegerDropdown
                smallish
                labelFormatter={(round: number) => `Round ${round}`}
                min={0}
                max={numberOfRounds}
                onChange={(selected) =>
                  modelContext.updateSelectedRound(selected.value)
                }
                value={state.selectedRound}
              />
            </InlineGroup>
          )}
          <InlineGroup block right spaceBetweenElements={2}>
            {hasAdjustments && (
              <IconButton
                disabled={isRecalculating}
                icon={"calculator"}
                text={"Recalculate and Close"}
                onClick={handleCloseOrRecalculate}
              />
            )}
          </InlineGroup>
          <FacilitatorModelAdjustments
            eventId={eventId}
            selectedRound={state.selectedRound}
            configuration={state.config}
            selectedTeam={state.selectedTeam}
            teams={state.gameStatus.teams}
            onAdjustmentMade={handleAdjustmentMade}
          />
        </VerticalGroup>
      )}
    </ModelContext.Provider>
  );

  return (
    <SimpleModal
      showCloseButton={!isMobile && !hasAdjustments}
      showMobileCloseButton={isMobile && !hasAdjustments}
      isOpen={isOpen}
      size="scale"
      onClose={handleModalClose}
      isScrollable={!isMobile}
    >
      {modalContent}
    </SimpleModal>
  );
};

export default GameControllerFacilitatorAdjustmentsModal;
