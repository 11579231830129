import React, { useCallback, useMemo, useRef, useState } from "react";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import API from "../../../services/api";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import Text from "../../atoms/text/Text";
import { getMaxClicks as getBankBailOutsMaxClicks } from "./slides/RoundResultsBankBailOuts";
import { maxClicks as marketMargingMaxClicks } from "./slides/RoundResultsMarketMargins";
import { getMaxClicks as getDealRoomMaxClicks } from "./slides/RoundResultsDealRoomTable";
import { getMaxClicks as getCeoTransferMaxClicks } from "./slides/RoundResultsCeoTransfer";
import { maxClicks as executiveDecisionsTableMaxClicks } from "./slides/RoundResultsExecutiveDecisionsTable";
import { maxClicks as neoBankMaxClicks } from "./slides/RoundResultsNeoBankAmlIssue";
import { getMaxClicks as getRoyalCommissionMaxClicks } from "./slides/RoundResultsRoyalCommission";
import { getMaxClicks as getRegCapitalMaxClicks } from "./slides/RoundResultsRegulatoryCapitalIntervention";
import { getMaxClicks as getNewsRoomMaxClicks } from "./slides/RoundResultsNewsRoom";
import { useKeypress } from "../../../hooks/useKeypress";
import Icon from "../../atoms/icon/Icon";
import Clickable from "../../atoms/clickable/Clickable";
import useDimensions from "../../../hooks/useDimensions";
import RoundResultsPage from "./RoundResultsPage";
import { formatRoundedMinutes } from "../../../lib/date";
import RoundResultsPresenterSlidePreview from "./components/RoundResultsPresenterSlidePreview";
import { getTotalDurationEstimate } from "../reporting-templates/utils/durationUtils";
import InformationPopup from "../../organisms/information-popup/InformationPopup";
import { ThemeFontSize } from "../../../types/theme";
import RoundResultsFontMultiplierModal from "./components/RoundResultsFontMultiplierModal";
import { useResultsContext } from "./context/context";
import RoundResultsLayout from "./RoundResultsLayout";
import { Resizable } from "re-resizable";
import { wellRunMaxClicks } from "./slides/RoundResultsWellRunBanks";
import CopyToClipboard from "react-copy-to-clipboard";

import modelApi from "../../../services/modelApi";
import SpeakerNotes, { SpeakerNotesRef } from "./components/SpeakerNotes";
import { poorRiskMaxClicks } from "./slides/RoundResultsPoorRisk/RoundResultsPoorRisk";
import { keyOutcomesMaxClicks } from "./slides/RoundResultsKeyOutcomes";
import RemainingTimeEstimate from "./components/RemainingTimeEstimate";
import CurrentTimeDisplay from "./components/CurrentTimeDisplay";
import { Noop } from "../../../constants/functions";
import Checkbox from "../../atoms/form/input/Checkbox";
import CloseRoundModal from "../game/CloseRoundModal";
import ProgressModal from "../../organisms/standard-modal/ProgressModal";
import PresentResultsRndDropdown from "../game/PresentResultsRndDropdown";
import SquareIconButton from "../../molecules/square-icon-button/SquareIconButton";

const RENDERED_DIMENSIONS = { width: 1700, height: 800 };
const containerPadding = 4;
const headingHeight = 160;
const innerContainerPadding = 40;
const speakerNotesSizes: ThemeFontSize[] = [
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "2xl",
  "3xl",
];

interface Props {
  modelConfig: ModelAPI.ConfigurationResponse;
  data: ModelAPI.Reporting.ReportingResults;
  onSpeakerNotesSaved: () => void;
  handleRoundChange: (round: number) => void;
}

const RoundResultsPresenterLayout = ({
  modelConfig,
  data,
  onSpeakerNotesSaved,
  handleRoundChange,
}: Props) => {
  const context = useResultsContext();
  const [speakerNotesSize, setSpeakerNotesSize] = useState<ThemeFontSize>("lg");
  const [textSizeModalOpen, setTextSizeModalOpen] = useState(false);
  const [disableKeyPress, setDisableKeyPress] = useState(false);
  const [leftWidth, setLeftWidth] = useState("70%");
  const [rightWidth, setRightWidth] = useState("30%");
  const [publishResults, setPublishResults] = useState(true);
  const [closeRoundModalOpen, setCloseRoundModalOpen] = useState(false);
  const [closeRoundInProgress, setCloseRoundInprogress] = useState(false);
  const [closeRoundPercentage, setCloseRoundPercentage] = useState(0);
  const [closeRoundStatus, setCloseRoundStatus] = useState("");

  const handleResize = useCallback((event, direction, elementRef, delta) => {
    const totalWidth = elementRef.parentElement.clientWidth;
    const newLeftWidth =
      ((elementRef.clientWidth + delta.width) / totalWidth) * 100;
    const newRightWidth = 100 - newLeftWidth;

    setLeftWidth(`${newLeftWidth}%`);
    setRightWidth(`${newRightWidth}%`);
  }, []);

  const [
    { previewHeight, previewWidth, availableHeight, availableWidth },
    setContainerRef,
  ] = useDimensions(
    headingHeight,
    containerPadding,
    innerContainerPadding,
    RENDERED_DIMENSIONS,
  );

  const [sidebarDimensions, setSidebarRef] = useDimensions(
    1,
    0,
    0,
    RENDERED_DIMENSIONS,
  );

  const isSmallestSpeakerNoteSize = useMemo(
    () => speakerNotesSize === speakerNotesSizes[0],
    [speakerNotesSize],
  );
  const isBiggestSpeakerNoteSize = useMemo(
    () => speakerNotesSize === speakerNotesSizes[speakerNotesSizes.length - 1],
    [speakerNotesSize],
  );

  const increaseSpeakerFontSize = useCallback(() => {
    const currentIndex = speakerNotesSizes.indexOf(speakerNotesSize);
    if (!isBiggestSpeakerNoteSize) {
      setSpeakerNotesSize(speakerNotesSizes[currentIndex + 1]);
    }
  }, [speakerNotesSize, isBiggestSpeakerNoteSize]);

  const decreaseSpeakerFontSize = useCallback(() => {
    const currentIndex = speakerNotesSizes.indexOf(speakerNotesSize);
    if (!isSmallestSpeakerNoteSize) {
      setSpeakerNotesSize(speakerNotesSizes[currentIndex - 1]);
    }
  }, [speakerNotesSize, isSmallestSpeakerNoteSize]);

  const selectedLayout = useMemo(() => {
    return context.pages[context.pageIndex];
  }, [context.pageIndex, context.pages]);

  const nextLayout = useMemo(() => {
    return context.pages[context.pageIndex + 1];
  }, [context.pageIndex, context.pages]);

  const chartTypes = useMemo(
    () => [
      "business_loan_losses",
      "customer_complaints",
      "customers",
      "customer_satisfaction",
      "credit_rating",
      "employee_engagement",
      "incremental_fte",
      "incremental_fte_costs",
      "loan_impairment_expense",
      "regulator_sanctions",
      "reputation_index",
      "return_on_equity",
      "risk_incidents",
      "total_shareholder_return",
      "overall_winning_metric",
      "wholesale_funding_issues",
      "npat_&_roe",
    ],
    [],
  );
  const handleActualClick = useCallback(() => {
    if (!selectedLayout || !data) return;
    let maxClicks = 0;
    if (selectedLayout.type === "bank_bail_outs") {
      maxClicks = getBankBailOutsMaxClicks(data.bankBailouts);
    } else if (selectedLayout.type === "net_interest_margin") {
      maxClicks = marketMargingMaxClicks;
    } else if (selectedLayout.type === "deal_room_results") {
      maxClicks = getDealRoomMaxClicks(data.dealRoom!);
    } else if (selectedLayout.type === "ceo_transfer") {
      maxClicks = getCeoTransferMaxClicks(data.simulationType);
    } else if (selectedLayout.type === "executive_decisions_table") {
      maxClicks = executiveDecisionsTableMaxClicks;
    } else if (selectedLayout.type === "neobank_aml_issue") {
      maxClicks = neoBankMaxClicks;
    } else if (selectedLayout.type === "royal_commission") {
      maxClicks = getRoyalCommissionMaxClicks(data.royalCommissionEvent);
    } else if (selectedLayout.type === "regulatory_capital_intervention") {
      maxClicks = getRegCapitalMaxClicks(data.regulatoryCapitalIntervention);
    } else if (selectedLayout.type === "news_room") {
      maxClicks = getNewsRoomMaxClicks(false);
    } else if ([...chartTypes].includes(selectedLayout.type)) {
      maxClicks = 1;
    } else if (selectedLayout.type === "well_run_banks") {
      maxClicks = wellRunMaxClicks;
    } else if (selectedLayout.type === "poor_risk_management") {
      maxClicks = poorRiskMaxClicks;
    } else if (selectedLayout.type === "key_outcomes") {
      maxClicks = keyOutcomesMaxClicks;
    }
    if (context.clicks < maxClicks) {
      context.addClick();
    } else {
      if (nextLayout) {
        context.goNextPage();
      }
    }
  }, [selectedLayout, data, chartTypes, context, nextLayout]);

  const onNextClick = useCallback(() => {
    context.goNextPage();
  }, [context]);

  const onResetClick = useCallback(() => {
    context.updatePageAndClicks({
      pageIndex: 0,
      clicks: 0,
      version: new Date().getTime(),
    });
  }, [context]);

  const onBackClick = useCallback(() => {
    context.goPreviousPage();
  }, [context]);

  const speakerNotesRef = useRef<SpeakerNotesRef>(null);
  const handleIconClick = useCallback(() => {
    if (speakerNotesRef.current) {
      setDisableKeyPress(true);
      speakerNotesRef.current.handleEditChange(true);
    }
  }, []);

  const onRoundNumberChange = useCallback(
    (round: number) => {
      context.updateRound({ round });
      handleRoundChange(round);
    },
    [context, handleRoundChange],
  );

  const keymap = useMemo(() => {
    if (disableKeyPress) {
      return {} as Record<string, () => void>;
    }
    return {
      ArrowDown: handleActualClick,
      ArrowRight: handleActualClick,
      PageDown: handleActualClick,
      Enter: handleActualClick,
      Space: handleActualClick,
      KeyN: handleActualClick,

      ArrowUp: onBackClick,
      ArrowLeft: onBackClick,
      PageUp: onBackClick,
      Backspace: onBackClick,
      KeyP: onBackClick,
    };
  }, [handleActualClick, onBackClick, disableKeyPress]);

  useKeypress({ keymap });

  const timeRemaining = useMemo(
    () =>
      getTotalDurationEstimate(
        context.pages,
        context.roundNumber,
        context.pageIndex,
      ),
    [context.pageIndex, context.roundNumber, context.pages],
  );

  const saveSpeakerNotes = async (notes: string) => {
    try {
      if (
        context.eventId &&
        context.roundNumber !== undefined &&
        data?.layouts
      ) {
        const updatedLayouts = data.layouts.map((layout) => ({
          id: layout.id,
          speakerNotes:
            layout.id === selectedLayout?.id ? notes : layout.speakerNotes,
        }));
        await modelApi.updateReportingTemplate(
          context.eventId,
          context.roundNumber,
          {
            layouts: updatedLayouts,
          },
        );
        onSpeakerNotesSaved();
      } else {
        console.error(
          "Unable to save speaker notes: Missing eventId, roundNumber, or data.layouts",
        );
      }
      setDisableKeyPress(false);
    } catch (error) {
      console.error("Error saving speaker notes:", error);
    }
  };

  const publishResultsAndCloseRound = useCallback(async () => {
    try {
      // setError(null);
      setCloseRoundInprogress(true);
      if (context.cast) {
        setCloseRoundStatus("Stopping cast");
        context.updateCastState({ cast: false });
        setCloseRoundPercentage(25);
      }

      if (publishResults) {
        setCloseRoundStatus("Publishing results");
        await modelApi.publishReporting(context.eventId, context.roundNumber);
        setCloseRoundPercentage(50);
      }

      setCloseRoundStatus("Closing round");
      await API.closeRound(context.eventId, context.roundNumber);
      setCloseRoundPercentage(75);

      setCloseRoundStatus("Opening round");
      await API.startRound(context.eventId, context.roundNumber + 1);
      setCloseRoundPercentage(75);

      setCloseRoundInprogress(false);
      setCloseRoundModalOpen(false);
    } catch (e) {
      setCloseRoundInprogress(false);
      throw e;
    }
  }, [context, publishResults]);

  return (
    <>
      {textSizeModalOpen && (
        <RoundResultsFontMultiplierModal
          isOpen={textSizeModalOpen}
          onClose={() => setTextSizeModalOpen(false)}
        />
      )}
      {closeRoundModalOpen && (
        <CloseRoundModal
          isOpen={closeRoundModalOpen}
          onClose={() => setCloseRoundModalOpen(false)}
          onComplete={publishResultsAndCloseRound}
          totalRounds={14}
          round={context.roundNumber}
          publishResults={publishResults}
        />
      )}
      {closeRoundInProgress && (
        <ProgressModal
          percentageDone={closeRoundPercentage}
          message={closeRoundStatus}
          onClose={Noop}
        />
      )}
      {data && (
        <div style={{ height: "100vh", width: "100%" }}>
          <InlineGroup fullHeight block>
            <Resizable
              defaultSize={{
                width: leftWidth,
                height: "100%",
              }}
              size={{
                width: leftWidth,
                height: "100%",
              }}
              enable={{
                right: true,
                left: false,
                top: false,
                bottom: false,
                topRight: false,
                topLeft: false,
                bottomRight: false,
                bottomLeft: false,
              }}
              handleStyles={{
                right: {
                  width: "2px",
                  height: "100%",
                  backgroundColor: "lightgray",
                  position: "absolute",
                  right: "0",
                  top: "0",
                  cursor: "col-resize",
                  zIndex: 1,
                },
              }}
              onResize={handleResize}
            >
              <VerticalGroup
                full
                spread
                bgColour="darkgrey"
                ref={setContainerRef}
              >
                <VerticalGroup wide>
                  <InlineGroup
                    className="pl-2 pr-2"
                    verticalCenter
                    block
                    evenWidthChildren
                  >
                    <InlineGroup verticalCenter>
                      <RemainingTimeEstimate timeRemaining={timeRemaining} />
                    </InlineGroup>
                    <InlineGroup verticalCenter right>
                      <CurrentTimeDisplay />
                    </InlineGroup>
                  </InlineGroup>
                  <VerticalGroup className="p-4" center wide>
                    {previewHeight && (
                      <RoundResultsPresenterSlidePreview
                        availableWidth={availableWidth}
                        availableHeight={availableHeight}
                        previewWidth={previewWidth}
                        previewHeight={previewHeight}
                      >
                        <RoundResultsLayout data={data} />
                      </RoundResultsPresenterSlidePreview>
                    )}
                  </VerticalGroup>
                </VerticalGroup>
                <VerticalGroup wide spread full className="pl-2 pr-2">
                  <InlineGroup
                    center
                    verticalCenter
                    fullHeight
                    block
                    spaceBetweenElements={8}
                  >
                    <Clickable
                      onClick={() =>
                        context.updateCastState({ cast: !context.cast })
                      }
                    >
                      <Icon
                        type="casting"
                        colour={context.cast ? "blue" : "white"}
                        size={20}
                        tip={{
                          content: context.cast
                            ? "Stop casting"
                            : "Cast to participants",
                          id: "cast",
                        }}
                      />
                    </Clickable>
                    <VerticalGroup spaceBetweenElements={2}>
                      <Text size="lg" colour="white">
                        Presentation Round
                      </Text>
                      <PresentResultsRndDropdown
                        onChange={onRoundNumberChange}
                        maxRound={modelConfig.currentRound}
                        currentRound={context.roundNumber}
                      />
                    </VerticalGroup>
                  </InlineGroup>
                  <InlineGroup
                    className="h-28"
                    verticalCenter
                    block
                    evenWidthChildren
                  >
                    <InlineGroup verticalCenter spaceBetweenElements={2}>
                      <Clickable onClick={() => setTextSizeModalOpen(true)}>
                        <Icon
                          type="textSize"
                          colour="white"
                          size={6}
                          tip={{ content: "Change text size", id: "tezt-size" }}
                        />
                      </Clickable>

                      <CopyToClipboard
                        text={`${window.location.origin}/results/games/${context.eventId}/presentation-reader`}
                      >
                        <Clickable onClick={Noop}>
                          <Icon
                            type="link"
                            colour={"white"}
                            size={6}
                            tip={{
                              content: "Copy presentation url",
                              id: "copy",
                            }}
                          />
                        </Clickable>
                      </CopyToClipboard>

                      <Clickable onClick={onResetClick}>
                        <Icon
                          type="refresh"
                          colour="white"
                          size={6}
                          tip={{
                            content: "Restart presentation",
                            id: "restart",
                          }}
                        />
                      </Clickable>
                    </InlineGroup>
                    <InlineGroup verticalCenter center>
                      <Clickable
                        onClick={onBackClick}
                        disabled={context.pageIndex === 0}
                      >
                        <Icon
                          type="leftChevron"
                          colour="white"
                          size={10}
                          tip={{
                            content: "Go to previous slide",
                            id: "previous",
                          }}
                        />
                      </Clickable>
                      <Text colour="white" size="lg">
                        Slide {context.pageIndex + 1} of {context.pages.length}
                      </Text>
                      <Clickable onClick={onNextClick} disabled={!nextLayout}>
                        <Icon
                          type="rightChevron"
                          colour="white"
                          size={10}
                          tip={{ content: "Go to next slide", id: "next" }}
                        />
                      </Clickable>
                      <InformationPopup
                        title="Controlling the slides"
                        body={
                          <>
                            <p>Use the arrow keys to control the slides:</p>
                            <p>Up arrow: Go back one slide</p>
                            <p>Down arrow: Click through the current slide</p>
                          </>
                        }
                      />
                    </InlineGroup>
                    <InlineGroup verticalCenter right>
                      <Text size="lg" colour="white">
                        Total Time Estimate:{" "}
                        {formatRoundedMinutes(
                          getTotalDurationEstimate(
                            context.pages,
                            context.roundNumber,
                            0,
                          ),
                        )}
                      </Text>
                    </InlineGroup>
                  </InlineGroup>
                </VerticalGroup>
              </VerticalGroup>
            </Resizable>
            <Resizable
              defaultSize={{
                width: rightWidth,
                height: "100%",
              }}
              size={{
                width: rightWidth,
                height: "100%",
              }}
              enable={{
                right: false,
                left: false,
                top: false,
                bottom: false,
                topRight: false,
                topLeft: false,
                bottomRight: false,
                bottomLeft: false,
              }}
            >
              <VerticalGroup
                full
                center
                bgColour="darkgrey"
                ref={setSidebarRef}
              >
                <VerticalGroup wide center>
                  <InlineGroup className="p-2" block verticalCenter center>
                    <Text size="lg" colour="white">
                      {nextLayout ? "Next Slide" : "End of Slides"}
                    </Text>
                  </InlineGroup>
                  <VerticalGroup className="p-4" center wide>
                    {nextLayout && sidebarDimensions.previewHeight && (
                      <RoundResultsPresenterSlidePreview
                        availableWidth={sidebarDimensions.availableWidth}
                        availableHeight={sidebarDimensions.availableHeight}
                        previewWidth={sidebarDimensions.previewWidth}
                        previewHeight={sidebarDimensions.previewHeight}
                        paddingLeft={20}
                        paddingRight={20}
                        heightOffset={20}
                      >
                        <RoundResultsPage
                          id={"sidebar"}
                          eventId={context.eventId}
                          data={data}
                          roundNumber={context.roundNumber}
                          onlyThesePages={[]}
                          startingLayout={nextLayout?.type}
                          hidePagination
                          noAnimations
                          noAudio
                          ignoreKeyPress
                        />
                      </RoundResultsPresenterSlidePreview>
                    )}
                    {!nextLayout && (
                      <VerticalGroup center spaceBetweenElements={2}>
                        {context.roundNumber === modelConfig.currentRound && (
                          <VerticalGroup
                            spaceBetweenElements={2}
                            center
                            className="mt-4"
                          >
                            <VerticalGroup
                              bgColour="blue"
                              wide
                              className="p-4"
                              curved
                              center
                              spaceBetweenElements={2}
                            >
                              <SquareIconButton
                                onClick={() => setCloseRoundModalOpen(true)}
                                colour="white"
                                bgColour="blue"
                                noBorder
                                curved
                                icon="play"
                              />
                              <Text size="xl" colour="white">
                                Next round
                              </Text>
                            </VerticalGroup>
                            <Checkbox
                              label="Publish results when proceeding"
                              name="publishResults"
                              colour="white"
                              onChange={(e) => {
                                setPublishResults(e.target.checked);
                              }}
                              checked={publishResults}
                            />
                          </VerticalGroup>
                        )}
                        {context.roundNumber !== modelConfig.currentRound && (
                          <VerticalGroup spaceBetweenElements={2} center>
                            <VerticalGroup
                              bgColour="blue"
                              wide
                              className="p-4"
                              curved
                              center
                              spaceBetweenElements={2}
                            >
                              <SquareIconButton
                                onClick={() =>
                                  context.updateRound({
                                    round: context.roundNumber + 1,
                                  })
                                }
                                colour="white"
                                bgColour="blue"
                                noBorder
                                curved
                                icon="presentation"
                              />
                              <Text size="xl" colour="white">
                                Present Round {context.roundNumber + 1}
                              </Text>
                            </VerticalGroup>
                          </VerticalGroup>
                        )}
                      </VerticalGroup>
                    )}
                  </VerticalGroup>
                </VerticalGroup>
                <VerticalGroup wide spread full>
                  <VerticalGroup
                    className="p-4"
                    wide
                    center
                    spaceBetweenElements={2}
                    half
                  >
                    <Text size="lg" colour="white">
                      Notes
                    </Text>
                    <SpeakerNotes
                      ref={speakerNotesRef}
                      initialValue={selectedLayout?.speakerNotes || ""}
                      onSave={(notes: any) => saveSpeakerNotes(notes)}
                      fontSize={speakerNotesSize}
                      onEditChange={() => setDisableKeyPress(true)}
                    />
                  </VerticalGroup>
                  <InlineGroup className="pl-2 pr-2 pb-4">
                    <Clickable
                      onClick={decreaseSpeakerFontSize}
                      disabled={isSmallestSpeakerNoteSize}
                    >
                      <Icon
                        type="roundMinus"
                        size={6}
                        colour={
                          isSmallestSpeakerNoteSize ? "grey1" : "secondaryBlue"
                        }
                        tip={{
                          content: "Decrease speaker note text size",
                          id: "speaker-minus",
                        }}
                      />
                    </Clickable>
                    <Clickable
                      onClick={increaseSpeakerFontSize}
                      disabled={isBiggestSpeakerNoteSize}
                    >
                      <Icon
                        type="roundPlus"
                        size={6}
                        colour={
                          isBiggestSpeakerNoteSize ? "grey1" : "secondaryBlue"
                        }
                        tip={{
                          content: "Increase speaker note text size",
                          id: "speaker-plus",
                        }}
                      />
                    </Clickable>
                    <Clickable onClick={handleIconClick}>
                      <Icon
                        type="edit"
                        size={6}
                        colour={"white"}
                        tip={{
                          content: "Edit speaker notes",
                          id: "speaker-edit",
                        }}
                      />
                    </Clickable>
                  </InlineGroup>
                </VerticalGroup>
              </VerticalGroup>
            </Resizable>
          </InlineGroup>
        </div>
      )}
    </>
  );
};

export default RoundResultsPresenterLayout;
