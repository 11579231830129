import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Layout from "./Layout";

import { IconType } from "./components/atoms/icon/Icon";

import Dashboard from "./components/pages/Dashboard";
import NotFound from "./components/pages/error/NotFound";

import LogOut from "./components/pages/LogOut";

import ErrorBoundary from "./components/pages/error/Error";
import { useUserActions } from "./context/userContext";

import OpenEvents from "./components/pages/events/list/OpenEvents";
import ClosedEvents from "./components/pages/events/list/ClosedEvents";
import CloneEvent from "./components/pages/events/event/components/CloneEvent";
import CreateEvent from "./components/pages/events/event/components/CreateEvent";
import ImportEvent from "./components/pages/events/event/components/ImportEvent";
import EventEditWizard, {
  SecondaryPageHeader as EventEditWizardSecondaryPageHeader,
  PageHeader as EventEditWizardPageHeader,
} from "./components/pages/events/event/EventEdit";

import GameController from "./components/pages/game/GameController";

import AuditLogs from "./components/pages/administration/audit/AuditLogs";

import Configuration from "./components/pages/administration/configuration/Configuration";

import ManageClients from "./components/pages/administration/clients/ClientList";
import EditClient from "./components/pages/administration/clients/ClientEdit";
import CreateClient from "./components/pages/administration/clients/ClientCreate";
import ClientDetails from "./components/pages/administration/clients/ClientDetails";

import ListUsers from "./components/pages/administration/users/UserList";
import EditUser from "./components/pages/administration/users/UserEdit";
import CreateUser from "./components/pages/administration/users/UserCreate";
import UserDetails from "./components/pages/administration/users/UserDetails";

import ListBrandings from "./components/pages/branding/BrandingList";
import EditBranding from "./components/pages/branding/BrandingEdit";
import CloneBranding from "./components/pages/branding/BrandingClone";
import CreateBranding from "./components/pages/branding/BrandingCreate";
import BrandingDetails from "./components/pages/branding/BrandingDetails";

import ListAssessments from "./components/pages/assessments/AssessmentList";
import EditAssessment from "./components/pages/assessments/AssessmentEdit";
import CreateAssessment from "./components/pages/assessments/AssessmentCreate";
import AssessmentDetails from "./components/pages/assessments/AssessmentDetails";

import ListSimulations from "./components/pages/simulations/SimulationList";
import EditSimulation, {
  Breadcrumb as EditSimulationBreadcrumb,
  PageHeader as EditSimulationPageHeader,
} from "./components/pages/simulations/SimulationEdit";
import CreateSimulation, {
  Breadcrumb as CreateSimulationBreadcrumb,
  PageHeader as CreateSimulationPageHeader,
} from "./components/pages/simulations/SimulationCreate";
import SimulationDetails from "./components/pages/simulations/SimulationDetails";
import AuthenticationErrorBoundary from "./components/pages/error/AuthenticationErrorBoundary";
import useRequiredRoles from "./hooks/useRequiredRoles";
import AssessmentAddQuestion from "./components/pages/assessments/Question/AssessmentAddQuestion";
import AssessmentAddGroup from "./components/pages/assessments/Group/AssessmentAddGroup";
import AssessmentSurveyPreview from "./components/pages/assessments/AssessmentSurveyPreview";
import AssessmentEditGroup from "./components/pages/assessments/Group/AssessmentEditGroup";
import AssessmentEditQuestion from "./components/pages/assessments/Question/AssessmentEditQuestion";

import Model, {
  SecondaryPageHeader as ModelSecondaryPageHeader,
} from "./components/pages/model/Model";

import RoundResults from "./components/pages/results/RoundResults";
import RoundResultsPresenter from "./components/pages/results/RoundResultsPresenter";
import RoundResultsPresentation from "./components/pages/results/RoundResultsPresentation";
import RoundResultsPresentationReader from "./components/pages/results/RoundResultsPresentationReader";
import RoundResultsPublished from "./components/pages/results/RoundResultsPublished";
import RoundResultsCast from "./components/pages/results/RoundResultsCast";
import { AssesssmentResponses } from "./components/pages/events/assessments/AssessmentResponses";
import { CurrentEventProvider } from "./context/CurrentEventContext";
import { EventBreadcrumb } from "./components/pages/events/event/components/EventBreadcrumb";
import { EventAssessmentBreadcrumb } from "./components/pages/events/assessments/EventAssessmentBreadcrumb";
import { AssessmentResponsesIndex } from "./components/pages/events/assessments/AssessmentResponsesIndex";
import { AssessmentSummary } from "./components/pages/events/assessments/AssessmentSummary";

import ListReportings from "./components/pages/reporting-templates/ReportingTemplatesList";
import CreateReportingTemplate from "./components/pages/reporting-templates/ReportingTemplateCreate";
import ReportingTemplateDetails from "./components/pages/reporting-templates/ReportingTemplateDetails";
import ReportingTemplateEdit from "./components/pages/reporting-templates/ReportingTemplateEdit";
import AssessmentCloneQuestion from "./components/pages/assessments/Question/AssessmentCloneQuestion";
import TabletConfig from "./components/pages/events/tabletConfig/TabletConfiguration";
import useIsMobile from "./hooks/useIsMobile";
import ListIcons from "./components/pages/icons/ListIcons";

interface RouteConfigItem {
  path: string;
  exact?: boolean;
  component?: any;
  header?: string | React.ReactNode;
  secondaryHeader?: string | JSX.Element | null;
  sidebar?: {
    name: string;
    icon: IconType;
  };
  provider?: React.ComponentType;
  breadcrumb?: string | React.ComponentType | React.ElementType;
  routes?: RouteConfigItem[];
  requiredRoles?: API.Role[];
}
const HomePage = () => {
  const isMobile = useIsMobile();
  return isMobile ? <GameController /> : <Dashboard />;
};

const events: RouteConfigItem = {
  path: "/events",
  sidebar: {
    name: "Events",
    icon: "events",
  },
  breadcrumb: "Events",
  header: "Open Events",
  routes: [
    {
      path: "/events",
      exact: true,
      component: OpenEvents,
      header: "Open Events",
      sidebar: {
        name: "Open Events",
        icon: "unlock",
      },
      breadcrumb: "Open Events",
    },
    {
      path: "/events/closed",
      exact: true,
      component: ClosedEvents,
      header: "Closed Events",
      sidebar: {
        name: "Closed Events",
        icon: "lock",
      },
      breadcrumb: "Closed Events",
    },
    {
      path: "/events/add",
      header: "Add New Event",
      component: CreateEvent,
      breadcrumb: "Add Event",
    },
    {
      path: "/events/import",
      header: "Import New Event",
      component: ImportEvent,
      breadcrumb: "Import Event",
    },
    {
      path: "/events/:eventId/view",
      exact: true,
      component: EventEditWizard,
      breadcrumb: EventBreadcrumb,
      header: <EventEditWizardPageHeader />,
      secondaryHeader: <EventEditWizardSecondaryPageHeader />,
      provider: CurrentEventProvider,
      routes: [
        {
          path: "/events/:eventId/view/assessments/:assessmentId",
          breadcrumb: EventAssessmentBreadcrumb,
          component: AssessmentResponsesIndex,
          routes: [
            {
              path: "/events/:eventId/view/assessments/:assessmentId/responses",
              exact: true,
              component: AssesssmentResponses,
              header: "Assessment Responses",
              breadcrumb: "Responses",
            },
            {
              path: "/events/:eventId/view/assessments/:assessmentId/summary",
              exact: true,
              component: AssessmentSummary,
              header: "Assessment Summary",
              breadcrumb: "Summary",
            },
          ],
        },
      ],
    },
    {
      path: "/events/:eventId/clone",
      exact: true,
      component: CloneEvent,
      breadcrumb: "Clone Event",
      header: "Clone Event",
    },
    {
      path: "/events/tablet-config",
      component: TabletConfig,
      header: "Tablet Configuration",
      breadcrumb: "Tablet Configuration",
      exact: true,
      sidebar: {
        name: "Tablet Configuration",
        icon: "tabletConfig",
      },
      requiredRoles: ["admin", "superadmin"],
    },
  ],
};

const games: RouteConfigItem = {
  path: "/games",
  header: "Game Controller",
  breadcrumb: "Game Controller",
  routes: [
    {
      path: "/games",
      exact: true,
      component: GameController,
      header: "Game Controller",
      sidebar: {
        name: "Game Controller",
        icon: "gameController",
      },
    },
    {
      path: "/games/:eventId",
      component: GameController,
      header: "Game Controller",
    },
  ],
};

const administration: RouteConfigItem = {
  path: "/administration",
  header: "Administration",
  // breadcrumb: 'Administration',
  sidebar: {
    name: "Administration",
    icon: "filter",
  },
  requiredRoles: ["admin", "superadmin"],
  routes: [
    {
      path: "/administration/users",
      component: ListUsers,
      header: "View Users",
      breadcrumb: "Users",
      exact: true,
      sidebar: {
        name: "Manage Users",
        icon: "users",
      },
      requiredRoles: ["admin", "superadmin"],
    },
    {
      path: "/administration/users/:userId/edit",
      component: EditUser,
      breadcrumb: "Edit User",
      header: "Edit User",
      requiredRoles: ["admin", "superadmin"],
    },
    {
      path: "/administration/users/add",
      component: CreateUser,
      breadcrumb: "Add New User",
      header: "Add New User",
      requiredRoles: ["admin", "superadmin"],
    },
    {
      path: "/administration/users/:userId/view",
      component: UserDetails,
      header: "View User",
      exact: true,
      breadcrumb: "View User",
      requiredRoles: ["admin", "superadmin"],
    },
    {
      path: "/administration/clients",
      component: ManageClients,
      header: "View Clients",
      breadcrumb: "Clients",
      exact: true,
      sidebar: {
        name: "Manage Clients",
        icon: "clients",
      },
      requiredRoles: ["superadmin"],
    },
    {
      path: "/administration/clients/:clientId/edit",
      component: EditClient,
      breadcrumb: "Edit Client",
      header: "Edit Client",
      requiredRoles: ["superadmin"],
    },
    {
      path: "/administration/clients/add",
      component: CreateClient,
      breadcrumb: "Add New Client",
      header: "Add New Client",
      requiredRoles: ["superadmin"],
    },
    {
      path: "/administration/clients/:clientId/view",
      component: ClientDetails,
      header: "View Client",
      exact: true,
      breadcrumb: "View Client",
      requiredRoles: ["superadmin"],
    },
    {
      path: "/administration/audit",
      component: AuditLogs,
      header: "Audit Logs",
      breadcrumb: "Audit Logs",
      exact: true,
      sidebar: {
        name: "Audit Logs",
        icon: "audit",
      },
      requiredRoles: ["superadmin"],
    },
    {
      path: "/administration/configuration",
      component: Configuration,
      header: "Configuration",
      breadcrumb: "Configuration",
      exact: true,
      sidebar: {
        name: "Configuration",
        icon: "settings",
      },
      requiredRoles: ["superadmin"],
    },
    {
      path: "/administration/configuration/:moduleId/:action",
      component: Configuration,
      header: "Configuration",
      exact: true,
    },
    {
      path: "/administration/configuration/:moduleId",
      component: Configuration,
      header: "Configuration",
      exact: true,
    },
    {
      path: "/administration/configuration/:moduleId/:resourceId/:action",
      component: Configuration,
      header: "Configuration",
      exact: true,
    },
  ],
};

const branding: RouteConfigItem = {
  path: "/brandings",
  header: "Branding Profiles",
  breadcrumb: "Branding",
  requiredRoles: ["admin", "superadmin", "standard", "facilitator"],
  routes: [
    {
      path: "/brandings",
      component: ListBrandings,
      header: "View Brandings",
      exact: true,
      sidebar: {
        name: "Branding",
        icon: "branding",
      },
      requiredRoles: ["admin", "superadmin", "standard", "facilitator"],
    },
    {
      path: "/brandings/:brandingId/edit",
      component: EditBranding,
      breadcrumb: "Edit Branding",
      header: "Edit Branding",
      requiredRoles: ["admin", "superadmin", "standard", "facilitator"],
    },
    {
      path: "/brandings/:brandingId/clone",
      component: CloneBranding,
      breadcrumb: "Clone Branding",
      header: "Clone Branding",
      requiredRoles: ["admin", "superadmin", "standard"],
    },
    {
      path: "/brandings/add",
      component: CreateBranding,
      breadcrumb: "Create New Branding Theme",
      header: "Create New Branding Theme",
      requiredRoles: ["admin", "superadmin", "standard"],
    },
    {
      path: "/brandings/:brandingId/view",
      component: BrandingDetails,
      header: "View Branding Theme",
      exact: true,
      breadcrumb: "View Branding Theme",
      requiredRoles: ["admin", "superadmin", "standard", "facilitator"],
    },
  ],
};

const assessments: RouteConfigItem = {
  path: "/assessments",
  header: "Assessment Profiles",
  breadcrumb: "Assessments",
  requiredRoles: ["admin", "superadmin", "standard"],
  routes: [
    {
      path: "/assessments",
      component: ListAssessments,
      header: "View Assessments",
      exact: true,
      sidebar: {
        name: "Assessments",
        icon: "assessments",
      },
      requiredRoles: ["admin", "superadmin", "standard"],
    },
    {
      path: "/assessments/:assessmentId/edit",
      component: EditAssessment,
      breadcrumb: "Edit Assessment",
      header: "Edit Assessment",
      exact: true,
      requiredRoles: ["admin", "superadmin", "standard"],
      routes: [
        {
          path: "/assessments/:assessmentId/groups/:groupId/add-question",
          component: AssessmentAddQuestion,
          header: "Add Question",
          exact: true,
          breadcrumb: "Add Question",
        },
        {
          path: "/assessments/:assessmentId/groups/:groupId/questions/:questionId/edit",
          component: AssessmentEditQuestion,
          header: "Edit Question",
          exact: true,
          breadcrumb: "Edit Question",
        },

        {
          path: "/assessments/:assessmentId/groups/:groupId/questions/:questionId/clone",
          component: AssessmentCloneQuestion,
          header: "Clone Question",
          exact: true,
          breadcrumb: "Clone Question",
        },

        {
          path: "/assessments/:assessmentId/edit/add-group",
          component: AssessmentAddGroup,
          header: "Add Group",
          exact: true,
          breadcrumb: "Add Group",
        },
        {
          path: "/assessments/:assessmentId/edit/:groupId/edit",
          component: AssessmentEditGroup,
          header: "Edit Group",
          exact: true,
          breadcrumb: "Edit Group",
        },
        {
          path: "/assessments/:assessmentId/edit/preview",
          component: AssessmentSurveyPreview,
          header: "Preview",
          exact: true,
          breadcrumb: "Preview",
        },
      ],
    },
    {
      path: "/assessments/add",
      component: CreateAssessment,
      breadcrumb: "Create New Assessment",
      header: "Create New Assessment",
      requiredRoles: ["admin", "superadmin", "standard"],
    },
    {
      path: "/assessments/:assessmentId/view",
      component: AssessmentDetails,
      header: "View Assessment",
      exact: true,
      breadcrumb: "View Assessment",
      requiredRoles: ["admin", "superadmin", "standard"],
    },
  ],
};

const simulations: RouteConfigItem = {
  path: "/simulations",
  header: "Simulations",
  breadcrumb: "Simulations",
  requiredRoles: ["admin", "superadmin", "standard"],
  routes: [
    {
      path: "/simulations",
      component: ListSimulations,
      header: "View Simulations",
      exact: true,
      sidebar: {
        name: "Simulations",
        icon: "simulations",
      },
      requiredRoles: ["admin", "superadmin", "standard"],
    },
    {
      path: "/simulations/:simulationId/edit",
      component: EditSimulation,
      breadcrumb: EditSimulationBreadcrumb,
      header: <EditSimulationPageHeader />,
      requiredRoles: ["admin", "superadmin", "standard"],
    },
    {
      path: "/simulations/add",
      component: CreateSimulation,
      breadcrumb: CreateSimulationBreadcrumb,
      header: <CreateSimulationPageHeader />,
      requiredRoles: ["admin", "superadmin", "standard"],
    },
    {
      path: "/simulations/:simulationId/view",
      component: SimulationDetails,
      header: "View Simulation",
      exact: true,
      breadcrumb: "View Simulation",
      requiredRoles: ["admin", "superadmin", "standard"],
    },
  ],
};

const model: RouteConfigItem = {
  path: "/events/:eventId/debug",
  header: "Debug Model",
  routes: [
    {
      path: "/events/:eventId/debug",
      component: Model,
      header: "Debug Model",
      exact: true,
      secondaryHeader: <ModelSecondaryPageHeader />,
    },
  ],
};

const reporting: RouteConfigItem = {
  path: "/reporting-templates",
  header: "Reporting Templates",
  breadcrumb: "Reporting Templates",
  requiredRoles: ["admin", "superadmin", "standard"],
  routes: [
    {
      path: "/reporting-templates",
      component: ListReportings,
      header: "View Reportings Templates",
      exact: true,
      sidebar: {
        name: "Reporting Templates",
        icon: "report",
      },
      requiredRoles: ["admin", "superadmin", "standard", "facilitator"],
    },
    {
      path: "/reporting-templates/:reportingTemplateId/edit",
      component: ReportingTemplateEdit,
      breadcrumb: "Edit Presentation",
      header: "Edit Presentation",
      exact: true,
      requiredRoles: ["admin", "superadmin", "standard", "facilitator"],
    },
    {
      path: "/reporting-templates/add",
      component: CreateReportingTemplate,
      breadcrumb: "Create New Reporting Template",
      header: "Create New Reporting Template",
      requiredRoles: ["admin", "superadmin", "standard"],
    },
    {
      path: "/reporting-templates/:reportingTemplateId/view",
      component: ReportingTemplateDetails,
      exact: true,
      breadcrumb: "View Template",
      header: "View Template",
      requiredRoles: ["admin", "superadmin", "standard", "facilitator"],
    },
  ],
};

const icons: RouteConfigItem = {
  path: "/icons",
  header: "Icons",
  breadcrumb: "Icons",
  routes: [
    {
      path: "/icons",
      component: ListIcons,
      header: "View Iconss",
      exact: true,
    },
  ],
};

const withRequiredRoles = (route: RouteConfigItem) => {
  if (!route.requiredRoles) {
    return route.component;
  }

  if (!route.requiredRoles.length) {
    return route.component;
  }

  const RouteComponent = (
    props: React.ComponentProps<typeof route.component>,
  ) => {
    useRequiredRoles(route.requiredRoles);
    return <route.component {...props} />;
  };

  const AuthRoute = (props: React.ComponentProps<typeof route.component>) => {
    return (
      <AuthenticationErrorBoundary fallback={NotFound}>
        <RouteComponent {...props} />
      </AuthenticationErrorBoundary>
    );
  };

  return AuthRoute;
};

const wrapRoles = (config?: RouteConfigItem[]): RouteConfigItem[] => {
  if (!config) {
    return [];
  }

  return config.map((route) => ({
    ...route,
    routes: wrapRoles(route.routes),
    component: withRequiredRoles(route),
  }));
};

export const routeConfig: RouteConfigItem[] = wrapRoles([
  {
    path: "/",
    exact: true,
    component: HomePage,
    header: "Home Dashboard",
  },
  {
    path: "/logout",
    component: LogOut,
  },
  simulations,
  branding,
  events,
  assessments,
  games,
  reporting,
  administration,
  model,
  icons,
]);

const withProvidersAndLayout =
  (providers: React.ComponentType[], Component: React.ComponentType) => () => {
    const component = providers.reduceRight(
      (children, Provider) => {
        return <Provider>{children}</Provider>;
      },
      <Layout>
        <Component />
      </Layout>,
    );

    return component;
  };

function Nested({
  parentRoute,
  routes,
  providers = [],
}: {
  parentRoute?: RouteConfigItem;
  routes: RouteConfigItem[];
  providers?: React.ComponentType[];
}): JSX.Element[] {
  return routes
    .map((route) => {
      const currentProviders = [
        ...providers,
        ...(route.provider ? [route.provider] : []),
      ];
      return [
        ...(route.routes && route.routes.length
          ? Nested({
              routes: route.routes,
              parentRoute: route,
              providers: currentProviders,
            })
          : []),
        route.component && (
          <Route
            key={`${parentRoute && parentRoute.path} > ${route.path}`}
            exact={route.exact}
            path={route.path}
            component={withProvidersAndLayout(
              currentProviders,
              route.component,
            )}
          />
        ),
      ];
    })
    .flat();
}

// do not regenerate each route change.
const routes = Nested({ routes: routeConfig });

function Routes() {
  const { logout } = useUserActions();

  return (
    <BrowserRouter basename={process.env.REACT_APP_PATH_PREFIX}>
      <Switch>
        <Route
          path="/results/games/:eventId/presenter"
          exact
          component={RoundResultsPresenter}
        />
        <Route
          path="/results/games/:eventId/presentation"
          exact
          component={RoundResultsPresentation}
        />
        <Route
          path="/results/games/:eventId/presentation-reader"
          exact
          component={RoundResultsPresentationReader}
        />
        <Route
          path="/results/games/:eventId/cast/:castKey"
          exact
          component={RoundResultsCast}
        />
        <Route
          path="/results/games/:eventId/rounds/:roundNumber"
          exact
          component={RoundResults}
        />
        <Route
          path="/results/games/:eventId/:publishedResultsId"
          exact
          component={RoundResultsPublished}
        />
        <Route>
          <ErrorBoundary logout={logout}>
            <Switch>
              {routes}
              <Route component={withProvidersAndLayout([], NotFound)} />
            </Switch>
          </ErrorBoundary>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
